import { createSlice } from '@reduxjs/toolkit';
import { IRbacUser } from '../../../context/UserContext';
import { USER_TYPES } from '../../../shared/enums';
interface InitialAppState {
  currentUser: IRbacUser,
  persona: string,
  isTrainer: boolean
}
const initialState : InitialAppState = {
  currentUser: {
    id: "",
    userId: "",
    name: "",
    organizationId: "",
    organizationName: "",
    scopes: {},
  },
  persona: "",
  isTrainer: false

};

const userSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setPersona: (state, action) => {
      state.persona = action.payload;
      if(action.payload === USER_TYPES.TRAINER || action.payload === USER_TYPES.ADMIN){
        state.isTrainer = true;
      }
    }
  },
});

export default userSlice.reducer;
export const { setCurrentUser, setPersona } = userSlice.actions;
