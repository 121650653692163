import { Box, Button, Popover, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import TimelineIcon from '@mui/icons-material/Timeline';
import './Header.scss';
// import { DayPickerRangeController } from 'react-dates';
import { DateCalendar, DatePicker } from '@mui/x-date-pickers';
import { insertUrlParam } from '../../../shared/utils/url-helpers';
import { SelectionType, SELECTION_TYPE, useAnalytics } from '../../../states/analytics';
import { IDateRange } from '../../../entities/Analytics/AnalyticsProcedure';
import { useLocation } from 'react-router-dom';
import { DATE_FORMAT } from '../../../shared/utils/const-helpers';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  onDateRangeChange: (dataRange: IDateRange) => void;
  overview?: string;
  setOverview?: any;
}

const Header: React.FC<HeaderProps> = ({ onDateRangeChange, overview, setOverview }) => {
  const saveState = useAnalytics((state) => state.setState);
  const saveSelectedDateRange = useAnalytics((state) => state.setSelectedDateRange);
  const saveSelectedType = useAnalytics((state) => state.setSelectedType);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [currentSelection, setCurrentSelection] = useState(SELECTION_TYPE.THIS_MONTH as SelectionType);
  const [selectedFilter, setSelectedFilter] = useState<IDateRange>();
  const [selectedFilterText, setSelectedFilterText] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().startOf('month'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('month'));
  const open = Boolean(anchorEl);
  const id = open ? 'custom-data-range-selection-popover' : undefined;

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');

  useEffect(() => {
    if (!(filterType && startDate && endDate)) {
      setCurrentSelection(SELECTION_TYPE.THIS_MONTH);
      handleFilterSelection(SELECTION_TYPE.THIS_MONTH);
    } else {
      setCurrentSelection(filterType as SelectionType);
      updateFilterSelection(filterType as SelectionType, moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT));
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (selectedFilter) {
      onDateRangeChange(selectedFilter);
    }
  }, [onDateRangeChange, selectedFilter]);

  const updateFilterSelection = (selectionType: SelectionType, startDate: Moment, endDate: Moment) => {
    if (!(selectionType && startDate && endDate)) {
      return;
    }
    // Update the URL query params without reloading the page
    insertUrlParam('filter', selectionType);
    insertUrlParam('startDate', startDate.format(DATE_FORMAT));
    insertUrlParam('endDate', endDate.format(DATE_FORMAT));

    setCurrentSelection(selectionType);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);

    const selectedDateRange: IDateRange = {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    };

    // Save state
    saveState(selectedDateRange, selectionType);
    // saveSelectedDateRange(selectedDateRange);
    // saveSelectedType(selectionType);

    setSelectedFilter(selectedDateRange);

    let filterText = '';
    if (selectionType === SELECTION_TYPE.CUSTOM) {
      filterText = startDate.format('MMM DD, YYYY') + ' to ' + endDate.format('MMM DD, YYYY');
    } else {
      filterText = startDate.format('MMM YYYY');
    }
    setSelectedFilterText(filterText);
  };

  const handleFilterSelection = (selectionType: SELECTION_TYPE) => {
    let selected = moment();
    let startDate = moment(selected).startOf('month');
    let endDate = moment(selected).endOf('month');

    if (selectionType === SELECTION_TYPE.LAST_MONTH) {
      selected = moment().subtract(1, 'month');
      startDate = moment(selected).startOf('month');
      endDate = moment(selected).endOf('month');
    }

    updateFilterSelection(selectionType, startDate, endDate);
  };

  const handleDateRangeChange = (startDate: any, endDate: any) => {
    console.log(startDate, endDate)
    if (startDate && endDate) {
      updateFilterSelection(SELECTION_TYPE.CUSTOM, startDate, endDate);
    }
  };

  const handleCustomFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomFilterClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <Box
      component="div"
      className="analytics-header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box component="div">
        {/* <Stack direction="row" alignItems="center" gap={1}>
          <TimelineIcon />
          <Typography variant="h6">
            <>{t('ANALYTICS.ANALYTICS_FOR') + ' ' + selectedFilterText}</>
          </Typography>
        </Stack> */}
        <Tabs
          value={overview === 'trainer' ? 0 : overview === 'trainee' ? 1 : 2}
          onChange={(e, newTabIndex) => {
            setOverview(newTabIndex === 0 ? 'trainer' : newTabIndex === 1 ? 'trainee' : 'training');
          }}
          sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
        >
          <Tab
            sx={{ padding: 0 }}
            label={
              <Button size="small" variant={overview === 'trainer' ? 'contained' : 'outlined'} sx={{ margin: 0, borderRadius: '8px 0px 0px 8px' }}>
                Trainer Analytics
              </Button>
            }
          />
          <Tab
            sx={{ padding: 0 }}
            label={
              <Button size="small" variant={overview === 'trainee' ? 'contained' : 'outlined'} sx={{ margin: 0, borderRadius: '0px 0px 0px 0px' }}>
                Trainee Analytics
              </Button>
            }
          />
          <Tab
            sx={{ padding: 0 }}
            label={
              <Button size="small" variant={overview === 'training' ? 'contained' : 'outlined'} sx={{ margin: 0, borderRadius: '0px 8px 8px 0px' }}>
                Training Analytics
              </Button>
            }
          />
        </Tabs>
      </Box>
      <Box component="div" className="filter-buttons">
        <Button size="small" variant={currentSelection === SELECTION_TYPE.THIS_MONTH ? 'contained' : 'outlined'} onClick={() => handleFilterSelection(SELECTION_TYPE.THIS_MONTH)}>
          {t('ANALYTICS.THIS_MONTH')}
        </Button>
        <Button size="small" variant={currentSelection === SELECTION_TYPE.LAST_MONTH ? 'contained' : 'outlined'} onClick={() => handleFilterSelection(SELECTION_TYPE.LAST_MONTH)}>
          {t('ANALYTICS.LAST_MONTH')}
        </Button>
        <Button size="small" variant={currentSelection === SELECTION_TYPE.CUSTOM ? 'contained' : 'outlined'} onClick={handleCustomFilterClick}>
          {t('ANALYTICS.CUSTOM')}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCustomFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box component='div' sx={{display:'flex', flexDirection: 'row'}}>
            <DateCalendar value={selectedStartDate} onChange={(event) => {
              handleDateRangeChange(event, selectedEndDate)
            }} disableFuture/>
            <DateCalendar value={selectedEndDate} onChange={(event) => {
              handleDateRangeChange(selectedStartDate, event)
            }} disableFuture/>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default Header;
