import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography, Autocomplete, Box, CardContent } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Toast } from '../../../components/Notifications/Notification';
import { IOrganization } from '../../../entities/Organization/Organization';
import OrganizationService from '../../../services/Organization/OrganizationService';
import { getMessage } from '../../../shared/utils/message-helpers';
import IntegrationForm from './IntegrationForm';
import { useFormik } from 'formik';
import { StyledCardDetailsView } from '../../../components/Styled/StyledCardDetailsView';

interface AddOrganizationProps {}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const CreateNewIntegrationInstance: React.FC<AddOrganizationProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const transformText = (text) => {
    if (!text) return ''; 
  
    const transformedText = text
      .toLowerCase()
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(' ');
    return transformedText;
  };
  const breadcrumbs = [
    <LinkRouter underline="hover" key="2" to="/integrations" sx={{ opacity: '0.7' }}>
      {t('BUTTON.INTEGRATIONS')}
    </LinkRouter>,
    <LinkRouter underline="hover" key="2" to={`/integration/${transformText(id)}`} sx={{ opacity: '0.7' }}>
      {t(`BUTTON.${id!.toString()}`)}
    </LinkRouter>,
    <Typography key="3" color="primary.main">
      {window.location.pathname.includes("edit")?t('BUTTON.EDIT_CONNECTOR'): t('BUTTON.CREATE_NEW_CONNECTOR')}
    </Typography>,
  ];
  
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const addOrganizationHandler = (organizationData: IOrganization) => {
    setLoading(true);
    OrganizationService.createOrganization(organizationData)
      .then((e) => {
        setLoading(false);
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        backHandler();
      })
      .catch(({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        console.error('AddOrganization -> addOrganizationHandler', response);
      });
  };

  const backHandler = () => {
    navigate('/organizations');
  };
  const { state } = useLocation();

  return (
    <>
      <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '32px' }}>
        <Breadcrumbs sx={{ margin: '0px 0px 0px 2rem' }} separator={<NavigateNextIcon color="primary" fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <StyledCardDetailsView>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'left',
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', p: 0, '&:last-child': { pb: 0 } }} className="user-form-content">
            <IntegrationForm />
          </CardContent>
        </Box>
      </StyledCardDetailsView>
    </>
  );
};

export default CreateNewIntegrationInstance;
