import { Alert, AlertTitle, Box } from '@mui/material';
import { CustomContentProps, SnackbarContent } from 'notistack';
import React, { memo } from 'react';

export interface INotificationProps extends CustomContentProps {
  title: string;
  message: string;
}

const NotificationSnackbar = React.forwardRef<
  HTMLDivElement,
  INotificationProps
>((props, forwardedRef) => {
  const { variant, message, title } = props;
  const severity = variant === 'default' ? 'info' : variant;
  const messages = message?.split('|');
  return (
    <SnackbarContent ref={forwardedRef}>
      <Alert severity={severity}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
        {messages?.map((message, index) => (
          <Box key={index} component="div">{message}</Box>
        ))}
      </Alert>
    </SnackbarContent>
  );
});

export default memo(NotificationSnackbar);
