import { Box, Typography, TableContainer, Table, TableBody, TableRow, TableCell, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import './IntroStepContainer.scss';
import { Fragment } from 'react';
import StepsPpeList from './StepsPpeList';
import { secondsToHms } from '../Analytics/ProcedureReports/TraineeTime';

interface props {
  introStep?: any;
  procedure?: any;
  workInstructionName?: string;
}

export const IntroStepContainer = ({ introStep, procedure, workInstructionName }: props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const rows = [
    {
      name: 'Procedure Name',
      value: procedure.procedureName,
    },
    {
      name: 'Work Instruction Name',
      value: workInstructionName,
    },
    {
      name: 'Cycle Time',
      value: secondsToHms(procedure.estimated_time) || '0s',
    },
    {
      name: 'Published By',
      value: procedure.publisher,
    },
    {
      name: 'Published Date',
      value: procedure.publishedOn
        ? new Date((procedure.publishedOn as any) * 1000).toLocaleString('en', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
        : null,
    },
    {
      name: 'Version No.',
      value: procedure.isPublished ? procedure.version : 'Draft',
    },
  ];

  return (
    <Box component="div" className="intro-container">
      {introStep === 1 ? (
        <>
          <TableContainer className="intro-table">
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <Fragment key={row.name}>
                    {(row.value || row.value === 0) && (
                      <TableRow>
                        <TableCell component="th" scope="row" sx={{ color: theme.palette.primary.main, fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '30%' }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '70%' }} align="left">
                          {row.value}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Typography className="intro-section-text" sx={{ mb: '8px' }}>
            {procedure?.ppeTitle ? `${procedure.ppeTitle}` : `Safety manual and requirements`}
          </Typography>
          <Typography className="section-text">
            {procedure?.ppeDescription?`${procedure.ppeDescription}`:`${t('3D_VIEWER.SAFETY_MANUAL')}. ${t('3D_VIEWER.WEAR_SAFETY_EQUIPMENT')}.`}
          </Typography>
          <StepsPpeList />
        </>
      )}
    </Box>
  );
};
