import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { PendingStatus, PendingStatusType } from '../../../entities/Analytics/AnalyticsProcedure';
import { useEffect, useMemo, useState } from 'react';
import TrainerService from '../../../services/trainer/TrainerService';

interface props {
  title: string;
  value?: any;
  info: string;
  buttonText: string;
  handleClick: any;
}

export const ActionRequiredCard = ({ title, value, info, buttonText, handleClick }: props) => {
  return (
    <Box component="div" sx={{ padding: '30px 0px', display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px dashed rgba(0, 0, 0, 0.1)', alignItems: 'center' }}>
      <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '14px' }}>{title}</Typography>
        <Typography sx={{ fontSize: '28px', margin: '6px 0px' }}>{value}</Typography>
        <Box component="span" className="style-text" sx={{ textAlign: 'left', fontSize: '14px' }}>
          {info}
        </Box>
      </Box>
      <Box component="div">
        <Button onClick={handleClick}>
          <Typography sx={{ fontSize: '14px', color: '#004b8d' }}>{buttonText}</Typography>
          <ArrowRightAltIcon sx={{ color: '#004b8d' }} />
        </Button>
      </Box>
    </Box>
  );
};

export const ActionRequired = ({ procedureState, filterType, dateRange }) => {
  const { t } = useTranslation();
  const [revisitRequestCount, setRevisitRequestCount] = useState();
  const navigate = useNavigate();

  const handleGotoAllPendingProceduresList = (procedureFilter) => {
    navigate(`/analytics/procedures/pending?filter=${filterType}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&procedure=Mine&procedureFilter=${procedureFilter}`);
  };

  const handleApprovalPendingList = () => {
    navigate(`/trainer/ApprovalRequests?filter=${filterType}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
  };

  const handleRevisitPendingList = () => {
    navigate(`/trainer/RevisitRequests`);
  };

  const unPublished = useMemo(() => {
    return procedureState.pendingProceduresData.filter((a) => a.pendingStatus.indexOf(PendingStatus.UNPUBLISHED as PendingStatusType) > -1 || a.pendingStatus.indexOf(PendingStatus.REQUIRE_STEPS as PendingStatusType) > -1 || (a.pendingStatus.indexOf(PendingStatus.UNAPPROVED as PendingStatusType) > -1 && a.procedure.approvalSentStatus !== 'pending' && a.procedure.approvalStatus !== 'pending')).length;
  }, [procedureState]);

  useEffect(() => {
    TrainerService.revisitRequestsCount().then((response) => {
      setRevisitRequestCount(response.data.data);
    });
  }, []);

  return (
    <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
      <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ fontSize: '14px', color: 'rgba(18, 24, 31, 0.6)', pb: '15px', borderBottom: '2px solid transparent', borderImage: 'linear-gradient(to right, rgba(18, 24, 31, 0.2),transparent)', borderImageSlice: 1 }}>Action Required</Typography>
        <ModeStandbyIcon sx={{ color: 'green', fontSize: '25px' }} />
      </Box>

      <ActionRequiredCard title={'Procedures Require your Approval'} value={procedureState?.pendingApprovals?.length || 0} info={'Sent by your fellow trainers'} buttonText={'Approve'} handleClick={() => handleApprovalPendingList()} />
      <ActionRequiredCard title={'Procedures Require your Revisit'} value={revisitRequestCount || 0} info={'Sent by your fellow trainers'} buttonText={'Approve'} handleClick={() => handleRevisitPendingList()} />
      <ActionRequiredCard title={'Unpublished Procedures'} value={unPublished} info={'Might require steps or approval to publish'} buttonText={'Publish'} handleClick={() => handleGotoAllPendingProceduresList(PendingStatus.UNPUBLISHED)} />
      <ActionRequiredCard title={'Rejected Procedures'} value={procedureState?.rejectedApprovals?.length || 0} info={'Sent by you to fellow trainers for approval'} buttonText={'See All'} handleClick={() => handleGotoAllPendingProceduresList(PendingStatus.REJECTED)} />
    </Box>
  );
};
