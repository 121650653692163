import React from 'react';
import { Avatar, styled, useTheme } from '@mui/material';

export const StyledAvatarRecordCount = (props) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        bgcolor: 'grey.300',
        color: '#000',
        height: '32px',
        padding: '0 5px',
      }}
      variant="rounded"
      {...props}
    >
      {props.children}
    </Avatar>
  );
};
