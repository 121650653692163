import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './LanguageSection.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import OrganizationService from '../../services/Organization/OrganizationService';
import LanguageService from '../../services/Language/LanguageService';
import { Toast } from '../Notifications/Notification';
import { getMessage } from '../../shared/utils/message-helpers';
import TextField from '@mui/material/TextField';
import { CustomSpecialChar } from '../Icons/CdsIcons';
import useCurrentTranslation from '../../hooks/useCurrentTranslation';
interface Props {
  onLoading: (flag: boolean) => void;
  handleClose: () => void;
  onClose?: any;
  open?: any;
  setOpen?: any;
  languageState?: boolean;
}

export const RenameTabsandLabels = (props: Props) => {
  const [count, setCount] = useState(1);
  const [lang, setLang]: any = useState({ id: '', value: '' });
  const [label, setLabel]: any = useState({
    CORE_PROCEDURES: {
      EN: 'Core Procedures',
      FR: 'Procédure de base',
      DE: 'Kernverfahren',
      ESP: 'Procedimientos principales',
    },
    PREREQUISITES: {
      EN: 'Prerequisites',
      FR: 'Prérequis',
      DE: 'Voraussetzung',
      ESP: 'Requisito previo',
    },
  });

  const [mainlabel, setMainLabel]: any = useState({
    STANDARD_NAME: {
      EN: 'STANDARD NAME',
      FR: 'NOM DE LA NORME',
      DE: 'STANDARDNAME',
      ESP: 'NOMBRE ESTÁNDAR',
    },
    CUSTOM_NAME: {
      EN: 'CUSTOM NAME',
      FR: "NOM D'USAGE",
      DE: 'BENUTZERDEFINIERTER NAME',
      ESP: 'NOMBRE PERSONALIZADO',
    },
  });

  const [preferedLanguage, setPreferedLanguage] = useState({ id: '', value: '' });
  const [languages, setLanguages] = useState([{ id: '', value: '' }]);
  const upperLanguageId = lang.id.toUpperCase();
  const { t } = useTranslation();
  const { currentTranslation } = useCurrentTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : t('PROCEDURE.PRE_REQUISITES');
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : t('PROCEDURE.CORE_PROCEDURES');

  const [customPrerequisiteName, setCustomPrerequisiteName] = useState<any>(systemPrerequisite);
  const [customCoreProcedure, setCustomCoreProcedure] = useState<any>(systemCoreProcedure);
  const [standardLabelName, setStandardLabelName] = useState<any>(`${t('SYSTEM_SETTINGS.STANDARD_NAME')}`);
  const [customLabelName, setCustomLabelName] = useState<any>(`${t('SYSTEM_SETTINGS.CUSTOM_NAME')}`);

  const handleChange = (event: SelectChangeEvent) => {
    const selected = languages.find((obj) => obj.value === event.target.value);
    setLang(selected);
    const LangId = selected?.id ? selected.id : 'EN';
    let upperLanguageId = LangId.toUpperCase();
    props.onLoading(true);
    OrganizationService.getTheme()
      .then((e) => {
        const {
          data: { data: themeData },
        } = e;
        setLabel(themeData.labels);
        console.log(themeData.labels);
        setCustomPrerequisiteName(themeData.labels.PREREQUISITES[upperLanguageId]);
        setCustomCoreProcedure(themeData.labels.CORE_PROCEDURES[upperLanguageId]);
        setStandardLabelName(mainlabel.STANDARD_NAME[upperLanguageId]);
        setCustomLabelName(mainlabel.CUSTOM_NAME[upperLanguageId]);
        props.onLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        props.onLoading(false);
      });
  };

  const changeLabel = async () => {
    const updatedLabel = { ...label };
    const upperLanguageId = lang.id.toUpperCase();
    updatedLabel.CORE_PROCEDURES[upperLanguageId] = customCoreProcedure;
    updatedLabel.PREREQUISITES[upperLanguageId] = customPrerequisiteName;
    props.onLoading(true);
    await OrganizationService.EditLabel(updatedLabel)
      .then((response) => {
        window.location.reload();
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        props.onLoading(false);
      });
  };

  const cancelClickHandler = () => {
    props.setOpen(false);
    props.onClose(false);
  };

  useEffect(() => {
    props.onLoading(true);
    LanguageService.getLanguages()
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        props.onLoading(true);
        OrganizationService.getTheme()
          .then((e) => {
            const {
              data: { data: themeData },
            } = e;

            setPreferedLanguage(themeData.language);
          })
          .catch(({ response }) => {
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
          })
          .finally(() => {
            props.onLoading(false);
          });
      });
  }, []);

  useEffect(() => {
    setCount(languages.length);
    if (preferedLanguage.id) {
      setLang(preferedLanguage);
      i18n.changeLanguage(preferedLanguage.id);
    }
  }, [preferedLanguage]);

  useEffect(() => {}, []);

  return (
    <Box sx={{ height: '18rem' }} component="div">
      <Box component="div">
        <Typography sx={{ color: '#1C1D1F', fontSize: '.75rem', padding: '0 .5rem' }}>
          {t('SYSTEM_SETTINGS.ALL_LANGUAGES') as string} ({count})
        </Typography>
      </Box>
      <Box component="div">
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select value={lang.value} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Preffered language' }} sx={{ width: '17rem', height: '2.5rem' }}>
            {languages.map((language) => (
              <MenuItem
                //defaultValue={preferedLanguage.value}
                key={language.id}
                value={language.value}
              >
                {t(language.value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Box component="div" sx={{ display: 'flex', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Typography sx={{ minWidth: '250px' }}>{standardLabelName}</Typography>
          <Typography sx={{ minWidth: '108px', display: 'flex' }}>
            {customLabelName}
            <Tooltip placement={'top'} title={t('SYSTEM_SETTINGS.RENAME_TABS_TOOLTIP')}>
              <Box component="div">
                <CustomSpecialChar />
              </Box>
            </Tooltip>
          </Typography>
        </Box>
        <TextField id="outlined-basic" value={t('ADD_WI.PREREQUISITE')} variant="outlined" aria-readonly disabled />
        <TextField id="outlined-basic" value={customPrerequisiteName} onChange={(e) => setCustomPrerequisiteName(e.target.value)} variant="outlined" />
        <TextField id="outlined-basic" value={t('ADD_WI.CORE_PROCEDURE')} variant="outlined" aria-readonly disabled />
        <TextField
          id="outlined-basic"
          value={customCoreProcedure}
          onChange={(e) => {
            setCustomCoreProcedure(e.target.value);
          }}
          variant="outlined"
        />
      </Box>
      <Box component="div" sx={{ margin: '1.5rem 0 0 .5rem' }}>
        <Button variant="contained" onClick={changeLabel}>
          {t('PROCEDURE_HISTORY.UPDATE')}
        </Button>
        <Button variant="outlined" sx={{ marginLeft: '8px' }} onClick={cancelClickHandler}>
          {t('BUTTON.CANCEL')}
        </Button>
      </Box>
    </Box>
  );
};
