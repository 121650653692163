import React, { useRef, useState } from 'react';
import { IWorkInstructions } from '../entities/Trainee/MockTrainee';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import * as HomePageAction from '../redux/features/HomePage/HomePage.slice';
import FileManagerService from '../services/FileManager/FileManagerService';
import TrainerService from '../services/trainer/TrainerService';
import { Toast } from '../components/Notifications/Notification';
import { useTranslation } from 'react-i18next';
import { CanceledError } from 'axios';

const useLoadFolders = () => {
  const [hasNext, setHasNext] = useState<boolean>(true);

  const [totalWorkInstructionsLength, setTotalWorkInstructionsLength] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [imageObject, setImageObject] = useState<Object>({});
  const [parentFolderId, setParentFolderId] = React.useState<String>('');
  const [loading, setLoading] = useState(false);
  const previousMoveController = useRef<any>(null);
  const [moveFolderData, setMoveFolderData] = React.useState<Array<any>>([]);
  const [moveParentFolderId, setMoveParentFolderId] = React.useState<string>('');
  const [moveCurrentPage, setMoveCurrentPage] = React.useState<number>(0);
  const [totalFoldersLength, setTotalFoldersLength] = useState(0);
  const [moveHasNext, setMoveHasNext] = useState<boolean>(true);
  const { t } = useTranslation();

  const loadFolderData = (folderId: string, search = '', sort = '', filter = '', page = 1, pageLimit = 20) => {
    setLoading(true);
    if (previousMoveController.current) {
      previousMoveController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousMoveController.current = controller;

    TrainerService.folderContent(folderId, page, pageLimit, search, sort, filter, signal)
      .then((e) => {
        setTotalFoldersLength(e?.data?.data?.folderCount);
        setMoveHasNext(e?.data?.data?.hasNext);

        if (page > 1) {
          setMoveFolderData([...moveFolderData, ...(e?.data?.data?.folders as any)]);
        } else {
          setMoveFolderData(e?.data?.data?.folders as any);
        }

        setMoveCurrentPage(page + 1);
        setMoveParentFolderId(folderId);
        setLoading(false);
      })
      .catch((error) => {
        if(error instanceof CanceledError) return;
        const {response} = error;
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loadFolderData,
    totalWorkInstructionsLength,
    currPage,
    parentFolderId,
    loading,
    setCurrPage,
    setParentFolderId,
    hasNext,
    moveFolderData,
    moveParentFolderId,
    moveCurrentPage,
    totalFoldersLength,
    moveHasNext,
    setMoveCurrentPage,
    setMoveParentFolderId,
    imageObject,
    setLoading,
  };
};
export default useLoadFolders;
