import { Add, AssignmentInd, ChangeCircle, Delete, Edit, MoreHoriz, RemoveRedEye, SettingsBackupRestore, Share } from '@mui/icons-material';
import { Backdrop, Box, Chip, IconButton, Link, Menu, MenuItem, Paper, Snackbar, SvgIcon, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CoreProcedureIcon, NormalProcedureIcon, ProductAffected, TemplateProcedureIcon } from '../../../Icons/CdsIcons';
import './TrainerProcedureCard.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import { environment } from '../../../../environment/environment';
import AssignUserDialog from '../../../Trainer/Dialog/AssignUserDialog/AssignUserDialog';
import { useFileUpload } from '../../../../shared/context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteWorkInstruction from '../../../Trainer/Dialog/DeleteWorkInstruction/DeleteWorkInstruction';
import { IuserList } from '../../../../entities/Trainer/MockTrainer';
import { IUserGroup } from '../../../../entities/Organization/User/UserGroup';
import VersionDialog from '../../../Trainer/Dialog/VersionDialog/versionDialog';
import ShareQR from '../../../Trainer/Dialog/ShareQR/ShareQR';
import { DragAndDropIconDefault } from '../../../Icons/CdsIcons';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ManagerApproval from '../../../Trainer/Dialog/ManagerApproval/ManagerApproval';
import TimelineIcon from '@mui/icons-material/Timeline';
import { trackEvent } from '../../../../App';
import { AmplitudeEvents, PROCEDURE_VISIBILITY_SCOPE, ProcedureDuplicationStatus } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Toast } from '../../../Notifications/Notification';
import PanToolIcon from '@mui/icons-material/PanTool';
import TranslateIcon from '@mui/icons-material/Translate';
import Loading from '../../../Loading/Loading';
import { LanguageSupport } from '../../../Trainer/Dialog/LanguageSupportDialog/LanguageSupportDialog';
import { DuplicateProcedure } from '../../../Trainer/Dialog/DuplicateProcedureDialog/DuplicateProcedureDialog';
import { RestoreProcedure } from '../../../Trainer/Dialog/RestoreDialog/RestoreProcedure';
import KeyIcon from '@mui/icons-material/Key';
import { RevokeAccess } from '../../../Trainer/Dialog/RevokeAccessDialog/RevokeAccessDialog';
import useCurrentTranslation from '../../../../hooks/useCurrentTranslation';
import TutorialService from '../../../../services/trainee/TraineeService';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import PeriodicReviewDialog from '../../../Trainer/Dialog/PeriodicReviewDialog/PeriodicReviewDialog';
import GradingIcon from '@mui/icons-material/Grading';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import RevisitApproval from '../../../Trainer/Dialog/PeriodicReviewDialog/RevisitApproval';
import moment from 'moment';
import RecertificationConfigurationDialog from '../../../Trainer/Dialog/RecertificationConfigurationDialog/RecertificationConfigurationDialog';
import { IProcedures, IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import { moveToLibrary } from '../../../../services/ModularProcedure/ModularProcedure';

interface Props {
  onClick?: () => void;
  isExternalPageOpened: (value: boolean) => void;
  title: string;
  authored?: boolean;
  workInstruction: any;
  provided?: any;
  snapshot?: any;
  procedureDetails: any;
  status: string;
  setLoading?: any;
  isDelete?: Boolean;
  setProcedures?: any;
  isRevisitRequest?: boolean;
}

const commonStyles = {
  border: '1px solid #1C1D1F',
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: '600',
};

const TrainerProcedureCard: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showVersionDialog, setShowVersionDialog] = useState<boolean>(false);
  const [showAssignDialog, setShowAssignDialog] = useState<boolean>(false);
  const [showPeriodicReviewDialog, setShowPeriodicReviewDialog] = useState<boolean>(false);
  const [showRecertificationConfigurationDialog, setShowRecertificationConfigurationDialog] = useState<boolean>(false);
  const [showLanguageSupportDialog, setShowLanguageSupportDialog] = useState<boolean>(false);
  const [showDuplicateProcedureDialog, setShowDuplicateProcedureDialog] = useState<boolean>(false);
  const [showRestoreProcedureDialog, setShowRestoreProcedureDialog] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<any>('');
  const [editText, setEditText] = useState<string>('');
  const [deleteText, setDeleteText] = useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showShareQRDialog, setShowShareQRDialog] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [userGroupReady, setUserGroupReady] = React.useState<boolean>(true);
  const [managerApproval, setManagerApproval] = React.useState<boolean>(false);
  const [revisitApproval, setRevisitApproval] = React.useState<boolean>(false);
  const [managerAction, setManagerAction] = React.useState<number>(0);
  const studioUrl = environment.AppConfigs.studioURL;
  const open = Boolean(anchorEl);
  const [userList, setUserList] = useState<IuserList>();
  const [userGroupList, setUserGroupList] = useState<any>();
  const [showRevokeAccessDialog, setShowRevokeAccessDialog] = useState<boolean>(false);
  const procedureType = useFileUpload();
  const navigate = useNavigate();
  const { companyId } = useParams<string>();
  const [recycleDialogCheck, setRecycleDialogCheck] = useState<boolean>(false);
  const [deleteDialogCheck, setDeleteDialogCheck] = useState<boolean>(false);
  const theme = useTheme();
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';
  const themePrimary = theme.palette.primary.main;
  
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const clickHandler = () => {
    setShowVersionDialog(true);
  };

  const deleteClickHandler = () => {
    setAnchorEl(null);
    setShowDeleteDialog(true);
    setRecycleDialogCheck(true);
  };

  const closeDeleteDialogHandler = () => {
    setShowDeleteDialog(false);
  };

  const closeLanguageSupportDialogHandler = () => {
    setShowLanguageSupportDialog(false);
    setAnchorEl(null);
  };
  const closeDuplicateProcedureDialogHandler = () => {
    setShowDuplicateProcedureDialog(false);
  };

  const closeRestoreProcedureDialogHandler = () => {
    setRefreshProcedure(!refreshProcedure);
    setShowRestoreProcedureDialog(false);
  };

  const closeRevokeAccessDialogHandler = () => {
    setShowRevokeAccessDialog(false);
  };

  const closeShareQRDialogHandler = () => {
    setShowShareQRDialog(false);
  };

  const assignClickHandler = () => {
    populateAssinUsers();
    populateAssignUserGroup();
    setShowAssignDialog(true);
  };

  const periodicReviewClickHandler = () => {
    setAnchorEl(null);
    setShowPeriodicReviewDialog(true);
  };

  const recertificationConfigurationClickHandler = () => {
    setAnchorEl(null);
    setShowRecertificationConfigurationDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDialogHandler = () => {
    setShowVersionDialog(false);
  };

  const closeManagerApproval = () => {
    setManagerApproval(false);
  };

  const closeRevisitApproval = () => {
    setRevisitApproval(false);
  };

  const closeAssignDialogHandler = () => {
    setShowAssignDialog(false);
  };
  const closePeriodicReviewHandler = () => {
    setShowPeriodicReviewDialog(false);
  };

  const closeRecertificationConfigurationHandler = () => {
    setShowRecertificationConfigurationDialog(false);
  };

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      setAuthToken(localStorage.getItem('token'));
    }
  }, [localStorage.getItem('token')]);

  const populateAssinUsers = async () => {
    let userList: any = [];
    setContentReady(false);
    await TrainerService.getUnassignedUser(props.procedureDetails.procedureId).then((response) => {
      for (let i = 0; i < response.data.data.length; i++) {
        if (JSON.stringify(userList).indexOf(response.data.data[i].email) === -1) {
          userList.push({
            email: response.data.data[i].email,
            firstName: response.data.data[i].firstName,
            lastName: response.data.data[i].lastName,
            role: response.data.data[i].role,
            workInstructions: response.data.data[i].workInstructions,
          });
        }
      }
    });
    setUserList(userList);
    setContentReady(true);
  };

  const populateAssignUserGroup = async () => {
    setUserGroupReady(false);
    await TrainerService.getUnassignedUsersFromUserGroup(props.procedureDetails.procedureId)
      .then((response) => {
        const userGroupLists = response.data.data;
        setUserGroupList(userGroupLists);
        setUserGroupReady(true);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const gotoCDSP = () => {
    openStudioEditorInATab(props.procedureDetails.organizationId, props.procedureDetails.workInstructionId, props.procedureDetails.procedureId, props.workInstruction.is2d);
    props.isExternalPageOpened(true);
    setButtonDisable(true);
  };

  const PreviewController = () => {
    trackEvent(AmplitudeEvents.PREVIEW_PROCEDURE, {
      procedure_id: props.procedureDetails.procedureId,
      active_version_id: props.procedureDetails.versionId,
      previewed_by: localStorage.getItem('email'),
    });
  };

  useEffect(() => {
    if (props.procedureDetails.tasks_count) {
      setCount(props.procedureDetails.tasks_count);
    }
  }, [props.procedureDetails.tasks_count]);

  useEffect(() => {
    props.procedureDetails.isPrerequisite ? setEditText('Edit Prerequisite') : setEditText('Edit Core Procedure');
    props.procedureDetails.isPrerequisite ? setDeleteText('Delete Prerequisite') : setDeleteText('Delete Core Procedure');
  }, [props.procedureDetails.isPrerequisite]);


  const { currentTranslation } = useCurrentTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : `${t('PROCEDURE.PRE_REQUISITES')}`;
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : `${t('PROCEDURE.CORE_PROCEDURES')}`;
  const displayProcedureText = (text) => {
    return text === 'Edit Prerequisite' ? `${t('SYSTEM_SETTINGS.EDIT')}${' '}${systemPrerequisite}` : `${t('SYSTEM_SETTINGS.EDIT')}${' '}${systemCoreProcedure}`;
  };

  const displayDeleteText = (text) => {
    return text === 'Delete Prerequisite' ? `${t('BUTTON.DELETE')}${' '}${systemPrerequisite}` : `${t('BUTTON.DELETE')}${' '}${systemCoreProcedure}`;
  };
  const editProcedure = (actionType) => {
    if (actionType === 'Edit Prerequisite') {
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Edit Pre Requisite',
      });
    } else {
      procedureType.dispatch({
        type: 'setProcedureType',
        payload: 'Edit Core Procedure',
      });
    }
    navigate(`/trainer/addProcedure/${props.workInstruction.workInstructionId}`, { state: { procedureDetails: props.procedureDetails } });
  };

  const procedureHistory = () => {
    navigate(`/trainer/procedureHistory/${companyId}/${props.workInstruction.workInstructionId}/${props.procedureDetails.procedureId}?workInstructionType=${props.workInstruction.is2d ? '2d' : '3d'}`);
  };

  const handleViewAnalytics = () => {
    handleClose();
    navigate(`/trainer/analytics/${props.workInstruction.workInstructionId}/${props.procedureDetails.procedureId}`);
  };

  const shareQRClickHandler = () => {
    setAnchorEl(null);
    setShowShareQRDialog(true);
  };

  const exportPdfHandler = () => {
    setAnchorEl(null);
    setLoading(true);
    TrainerService.exportProcedurePdf(props.procedureDetails.procedureId)
      .then((e) => {
        setLoading(false);
        var file = new Blob([e.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${props.procedureDetails.procedureName}.pdf`);
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) link.parentNode.removeChild(link);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        props.setLoading(false);
      });
  };

  const duplicateProcedure = () => {
    setAnchorEl(null);
    setShowDuplicateProcedureDialog(true);
  };

  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  const restoreProcedure = () => {
    setContentReady(false);
    TutorialService.restoreBinProcedure(props.procedureDetails.procedureId)
      .then((response) => {
        setContentReady(true);
        setShowRestoreProcedureDialog(true);
        setLoading(false);
      })
      .finally(() => setContentReady(true));
  };

  const deleteProcedurePermanentlyHandler = () => {
    setAnchorEl(null);
    setShowDeleteDialog(true);
    setDeleteDialogCheck(true);
  };

  useEffect(() => {}, [props.procedureDetails]);

  const twinProcedure = () => {
    setAnchorEl(null);
    setShowLanguageSupportDialog(true);
  };

  const handleRevokeAccess = () => {
    setAnchorEl(null);
    setShowRevokeAccessDialog(true);
  };

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const procedureQuery = query.get('procedure');

  const procedureReady = !props.procedureDetails.duplicateContentCreated || props.procedureDetails.duplicateContentCreated === ProcedureDuplicationStatus.READY;

  const navigateToPreview = () => {
    if (props.workInstruction.is2d) {
      navigate(`/assembly/${companyId}/${props.workInstruction?.workInstructionId}/${props.procedureDetails.procedureId}?workInstructionType=${props.workInstruction.is2d ? '2d' : '3d'}${props.isDelete ? '&isDelete=true' : ''}${props.isRevisitRequest ? '&isRevisitRequest=true' : ''}`);
    } else {
      window.location.href = `/assembly/${companyId}/${props.workInstruction?.workInstructionId}/${props.procedureDetails.procedureId}?workInstructionType=${props.workInstruction.is2d ? '2d' : '3d'}${props.isDelete ? '&isDelete=true' : ''}${props.isRevisitRequest ? '&isRevisitRequest=true' : ''}`;
    }
  };

  const handleAddToLibraryClick = (procedureDetails) => {
    moveToLibrary(procedureDetails.procedureId).then(() => {
      setRefreshProcedure(true);
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <Box component="div" className="trainer-requisits-link" onClick={props.onClick}>
        {!props.isRevisitRequest && !props.isDelete && <LanguageSupport openDialog={showLanguageSupportDialog} closeDialog={closeLanguageSupportDialogHandler} procedureId={props.procedureDetails.procedureId} />}
        {!props.isRevisitRequest && !props.isDelete && <DuplicateProcedure openDialog={showDuplicateProcedureDialog} closeDialog={closeDuplicateProcedureDialogHandler} procedureId={props.procedureDetails.procedureId} />}
        {showRevokeAccessDialog && <RevokeAccess openDialog={showRevokeAccessDialog} closeDialog={closeRevokeAccessDialogHandler} procedureId={props.procedureDetails.procedureId} />}
        {!props.isRevisitRequest && !props.isDelete && (
          <>
            <VersionDialog openDialog={showVersionDialog} closeDialog={closeDialogHandler} procedure={props.procedureDetails} style={{ padding: '24px' }} />
            <AssignUserDialog openDialog={showAssignDialog} closeDialog={closeAssignDialogHandler} userList={userList} userGroupList={userGroupList} workInstruction={props.workInstruction} procedure={props.procedureDetails} />
            <DeleteWorkInstruction openDialog={showDeleteDialog} isRecycle={recycleDialogCheck} isDeletePermanently={deleteDialogCheck} closeDialog={closeDeleteDialogHandler} id={props.procedureDetails.procedureId} isWorkinstruction={false} />
            <ShareQR openDialog={showShareQRDialog} closeDialog={closeShareQRDialogHandler} procedure={props.procedureDetails} workInstruction={props.workInstruction} />
            {showPeriodicReviewDialog && <PeriodicReviewDialog openDialog={showPeriodicReviewDialog} closeDialog={closePeriodicReviewHandler} userList={userList} userGroupList={userGroupList} workInstruction={props.workInstruction} procedure={props.procedureDetails} />}
            {showRecertificationConfigurationDialog && <RecertificationConfigurationDialog openDialog={showRecertificationConfigurationDialog} closeDialog={closeRecertificationConfigurationHandler} userList={userList} userGroupList={userGroupList} workInstruction={props.workInstruction} procedure={props.procedureDetails} />}
          </>
        )}
        {props.isDelete && <DeleteWorkInstruction openDialog={showDeleteDialog} isRecycle={recycleDialogCheck} isDeletePermanently={deleteDialogCheck} closeDialog={closeDeleteDialogHandler} id={props.procedureDetails.procedureId} isWorkinstruction={false} />}
        {props.isDelete && <RestoreProcedure openDialog={showRestoreProcedureDialog} closeDialog={closeRestoreProcedureDialogHandler} procedureName={props.procedureDetails.procedureName} workInstruction={props.procedureDetails.workInstructionName} />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!props.isRevisitRequest && !props.isDelete && props.provided && (
            <div
              className="grab"
              {...props.provided?.dragHandleProps}
              style={{
                cursor: 'grab',
                margin: '8px',
              }}
            >
              <DragAndDropIconDefault />
            </div>
          )}
          <Paper className={!props.authored ? 'btn-disable trainer-requisits-action' : 'trainer-requisits-action'} elevation={0} sx={{ borderRadius: '12px', flex: '1 0 auto', backgroundColor: props.snapshot?.isDragging ? 'white !important' : themePrimaryExtraLight, boxShadow: props.snapshot?.isDragging ? '0px 0px 40px rgba(0, 0, 0, 0.2)' : 'none' }}>
            <Box component="div" className="trainer-requisits-info">
              <CardHeading procedureDetails={props.procedureDetails} workInstruction={props.workInstruction} />

              <Box component="div">
                {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && !props.isRevisitRequest && !props.isDelete && !props.procedureDetails.isPublished && (props.status === '3D Model Ready' || props.workInstruction.is2d) && (!props.procedureDetails.duplicateContentCreated || props.procedureDetails.duplicateContentCreated === ProcedureDuplicationStatus.READY) && (
                  <Chip
                    icon={
                      props.procedureDetails.approvalStatus === 'rejected' ? (
                        <Edit sx={{ color: 'primary' }} />
                      ) : (
                        <SvgIcon sx={{ mr: 1 }}>
                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                          </svg>
                        </SvgIcon>
                      )
                    }
                    color="primary"
                    label={props.procedureDetails.approvalStatus === 'rejected' ? t('PROCEDURE.CARD_EDIT_STEPS') : t('PROCEDURE.CARD_ADD_STEPS')}
                    variant="outlined"
                    disabled={buttonDisable}
                    onClick={gotoCDSP}
                    sx={{ marginRight: '1rem' }}
                  />
                )}

                {props.isDelete && props.procedureDetails.tasks_count > 0 && procedureReady && (
                  <Link sx={{ color: theme.palette.text.primary }} onClick={() => navigateToPreview()}>
                    <Chip icon={<RemoveRedEye color="primary" />} color="primary" label={t('PROCEDURE.CARD_PREVIEW')} variant="outlined" disabled={buttonDisable} onClick={PreviewController} sx={{ marginRight: '1rem' }} />
                  </Link>
                )}
                {props.isRevisitRequest && props?.procedureDetails?.lastRevisitedOn + props?.procedureDetails?.revisitExpiry * 60 * 60 * 24 < moment().unix() && !props?.procedureDetails?.revisitComments && (
                  <>
                    <Chip
                      icon={<CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />}
                      color="primary"
                      label={t('PROCEDURE.APPROVE')}
                      variant="outlined"
                      onClick={() => {
                        setRevisitApproval(true);
                        setManagerAction(0);
                      }}
                      sx={{ marginRight: '1rem' }}
                    />
                    <Chip
                      icon={<HighlightOffIcon color="primary" sx={{ mr: 1 }} />}
                      color="primary"
                      label={t('ADD_USER.REJECT')}
                      variant="outlined"
                      onClick={() => {
                        setRevisitApproval(true);
                        setManagerAction(1);
                      }}
                      sx={{ marginRight: '1rem' }}
                    />
                  </>
                )}

                {props.isRevisitRequest && props.procedureDetails.tasks_count > 0 && procedureReady && (
                  <Link
                    sx={{ color: theme.palette.text.primary }}
                    onClick={() => {
                      navigateToPreview();
                    }}
                  >
                    <Chip icon={<RemoveRedEye color="primary" />} color="primary" label={t('PROCEDURE.CARD_PREVIEW')} variant="outlined" disabled={buttonDisable} onClick={PreviewController} sx={{ marginRight: '1rem' }} />
                  </Link>
                )}

                {props.isDelete && <Chip icon={<DeleteForeverIcon color="primary" />} color="primary" label={t('BUTTON.DELETE')} variant="outlined" onClick={deleteProcedurePermanentlyHandler} sx={{ marginRight: '1rem' }} />}
                {props.isDelete && <Chip icon={<SettingsBackupRestore color="primary" />} color="primary" label={t('BUTTON.RESTORE')} variant="outlined" disabled={buttonDisable} onClick={restoreProcedure} sx={{ marginRight: '1rem' }} />}
                {!props.isRevisitRequest && !props.isDelete && (props.status === '3D Model Ready' || props.procedureDetails.tasks_count > 0) && (!props.procedureDetails.duplicateContentCreated || props.procedureDetails.duplicateContentCreated === ProcedureDuplicationStatus.READY) && (
                  <Box component="span">
                    {(props.procedureDetails.versionId) && <Chip icon={<AssignmentInd />} color="primary" label={t('PROCEDURE.CARD_ASSIGN')} variant="outlined" disabled={buttonDisable} onClick={assignClickHandler} sx={{ marginRight: '1rem' }} />}
                    {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && !props.procedureDetails.isPublished && props.procedureDetails.tasks_count > 0 && !props.procedureDetails.approvalRequestedStatus && !props.procedureDetails.approvalSentStatus && (
                      <Chip
                        icon={
                          <SvgIcon sx={{ mr: 1 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                              <path d="M440-160v-326L336-382l-56-58 200-200 200 200-56 58-104-104v326h-80ZM160-600v-120q0-33 23.5-56.5T240-800h480q33 0 56.5 23.5T800-720v120h-80v-120H240v120h-80Z" />
                            </svg>
                          </SvgIcon>
                        }
                        color="primary"
                        label={t('PROCEDURE.CARD_PUBLISH')}
                        variant="outlined"
                        disabled={buttonDisable}
                        onClick={clickHandler}
                        sx={{ marginRight: '1rem' }}
                      />
                    )}
                  </Box>
                )}
                {!props.isRevisitRequest && !props.isDelete && (
                  <IconButton aria-label="delete" color="primary" id="demo-positioned-button" disabled={buttonDisable} aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                    <MoreHoriz fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Box>
            {props.procedureDetails.approvalStatus === 'rejected' && <Typography sx={{ fontSize: '16px', opacity: 0.6, textAlign: 'initial', padding: '20px 24px 0px', maxHeight: '90px', overflowY: 'scroll', wordBreak: 'break-all' }}>Rejection Comments: {props.procedureDetails.approvalComments}</Typography>}
            {props?.procedureDetails?.lastRevisitedOn + props?.procedureDetails?.revisitExpiry * 60 * 60 * 24 < moment().unix() && props?.procedureDetails?.revisitComments && <Typography sx={{ fontSize: '16px', opacity: 0.6, textAlign: 'initial', padding: '20px 24px 0px', maxHeight: '90px', overflowY: 'scroll', wordBreak: 'break-all' }}>Periodic Review Comments: {props.procedureDetails.revisitComments}</Typography>}
            <Menu color="primary" id="demo-positioned-menu" aria-labelledby="demo-positioned-button" anchorEl={anchorEl} open={open} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
              {props.procedureDetails.tasks_count > 0 && procedureReady && (
                <Link
                  sx={{ color: theme.palette.text.primary }}
                  onClick={() => {
                    navigateToPreview();
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      trackEvent(AmplitudeEvents.PREVIEW_PROCEDURE, {
                        procedure_id: props.procedureDetails.procedureId,
                        active_version_id: props.procedureDetails.versionId,
                        previewed_by: localStorage.getItem('email'),
                      });
                    }}
                  >
                    <RemoveRedEye color="primary" sx={{ mr: 1 }} />
                    {t('PROCEDURE.CARD_PREVIEW')}
                  </MenuItem>
                </Link>
              )}
              {props.procedureDetails.versionId && procedureReady && (
                <MenuItem onClick={shareQRClickHandler}>
                  <Share color="primary" sx={{ mr: 1 }} />
                  {t('PROCEDURE.CARD_SHARE')}
                </MenuItem>
              )}
              {props.procedureDetails.versionId && procedureReady && (
                <MenuItem onClick={exportPdfHandler}>
                  <OpenInNewIcon color="primary" sx={{ mr: 1 }} />
                  {t('PROCEDURE.EXPORT_PDF')}
                </MenuItem>
              )}
              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && props.procedureDetails.isPublished && procedureReady && (
                <MenuItem onClick={gotoCDSP}>
                  <Edit color="primary" sx={{ mr: 1 }} />
                  {t('PROCEDURE.CARD_EDIT_STEPS')}
                </MenuItem>
              )}

              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && props.procedureDetails.isPublished && (
                <MenuItem onClick={periodicReviewClickHandler}>
                  <GradingIcon color="primary" sx={{ mr: 1 }} />
                  {t('NAVBAR.PERIODIC_REVIEW')}
                </MenuItem>
              )}
              {props.procedureDetails.isPublished && (
                <MenuItem onClick={recertificationConfigurationClickHandler}>
                  <WorkspacePremiumIcon color="primary" sx={{ mr: 1 }} />
                  {t('NAVBAR.RECERTIFICATIONS_REVIEW')}
                </MenuItem>
              )}
              {props.procedureDetails.approvalRequestedStatus && props.procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && (
                <MenuItem
                  onClick={() => {
                    setManagerApproval(true);
                    setManagerAction(0);
                  }}
                >
                  <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
                  &nbsp;{t('PROCEDURE.APPROVE')}
                </MenuItem>
              )}
              {props.procedureDetails.approvalRequestedStatus && props.procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && (
                <MenuItem
                  onClick={() => {
                    setManagerApproval(true);
                    setManagerAction(1);
                  }}
                >
                  <HighlightOffIcon color="primary" sx={{ mr: 1 }} />
                  &nbsp;{t('ADD_USER.REJECT')}
                </MenuItem>
              )}
              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && props.workInstruction.is2d && props.procedureDetails.isPublished && procedureReady && (
                <MenuItem
                  onClick={() => {
                    handleAddToLibraryClick(props.procedureDetails);
                  }}
                >
                  <Add color="primary" sx={{ mr: 1 }} />
                  {t('SYSTEM_SETTINGS.ADD_TO_PROCEDURE_LIBRARY')}
                </MenuItem>
              )}

              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    editProcedure(editText);
                  }}
                >
                  <Edit color="primary" sx={{ mr: 1 }} />
                  {displayProcedureText(editText)}
                </MenuItem>
              )}

              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && procedureReady && (
                <MenuItem onClick={duplicateProcedure}>
                  <SvgIcon sx={{ mr: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                      <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
                    </svg>
                  </SvgIcon>

                  {props.procedureDetails.isPrerequisite ? `${t('SYSTEM_SETTINGS.DUPLICATE')}${' '}${systemPrerequisite}` : `${t('SYSTEM_SETTINGS.DUPLICATE')}${' '}${systemCoreProcedure}`}
                </MenuItem>
              )}
              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && procedureReady && (
                <MenuItem onClick={twinProcedure}>
                  <TranslateIcon color="primary" sx={{ mr: 1 }} />
                  {t('PROCEDURE.ADD_LANGUAGE_SUPPORT')}
                </MenuItem>
              )}

              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && props.procedureDetails.versionId && procedureReady && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    procedureHistory();
                  }}
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                      <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" />
                    </svg>
                  </SvgIcon>
                  {t('PROCEDURE.CARD_REVISION_HISTORY')}
                </MenuItem>
              )}
              {props.procedureDetails.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.REFERENCE && props.procedureDetails.versionId && procedureReady && (
                <MenuItem onClick={handleViewAnalytics}>
                  <TimelineIcon color="primary" sx={{ mr: 1 }} />
                  {t('PROCEDURE.VIEW_ANALYTICS')}
                </MenuItem>
              )}
              {props.procedureDetails.versionId && procedureReady && (
                <MenuItem onClick={handleRevokeAccess}>
                  <SvgIcon sx={{ mr: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                      <path d="M790-57 488-359q-32 54-87 86.5T280-240q-100 0-170-70T40-480q0-66 32.5-121t86.5-87L57-790l57-56 732 733-56 56Zm50-543 120 120-183 183-127-126 50-37 72 54 75-74-40-40H553l-80-80h367ZM280-320q51 0 90.5-27.5T428-419l-56-56-48.5-48.5L275-572l-56-56q-44 18-71.5 57.5T120-480q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T200-480q0-33 23.5-56.5T280-560q33 0 56.5 23.5T360-480q0 33-23.5 56.5T280-400Z" />
                    </svg>
                  </SvgIcon>
                  Remove Access
                </MenuItem>
              )}
              <MenuItem onClick={deleteClickHandler}>
                <Delete color="primary" sx={{ mr: 1 }} />
                {displayDeleteText(deleteText)}
              </MenuItem>
            </Menu>
          </Paper>
          {!props.isRevisitRequest && !props.isDelete && managerApproval && <ManagerApproval openDialog={managerApproval} closeDialog={closeManagerApproval} procedure={props.procedureDetails} action={managerAction} />}
          {props.isRevisitRequest && !props.isDelete && revisitApproval && <RevisitApproval openDialog={revisitApproval} closeDialog={closeRevisitApproval} procedure={props.procedureDetails} action={managerAction} />}
        </div>
      </Box>
    </>
  );
};

export default TrainerProcedureCard;

export const TaskCount = React.memo((props: {tasks_count: number, sx?: SxProps<Theme> | undefined}) => {
  const {tasks_count, sx} = props;
  const {t} = useTranslation();
  
  return (
    <Box component="span" className="style-text" sx={{ ...commonStyles, borderColor: 'grey.500', ...sx }}>
      {tasks_count} {t('PROCEDURE.CARD_STEPS')}{' '}
    </Box>
  );
});

export const ProcedureCardLabels = React.memo((props: { procedureDetails }) => {
  const { t } = useTranslation();
  if(!props.procedureDetails) return null;

  return (
    <>
      {props?.procedureDetails?.duplicateContentCreated === ProcedureDuplicationStatus.PROCESSING && (
        <Box component="span" className="style-text style-warning" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {t('PROCEDURE.PROCEDURE_IS_BEING_DUPLICATED')}
        </Box>
      )}
      {props?.procedureDetails?.duplicateContentCreated === ProcedureDuplicationStatus.FAILED && (
        <Box component="span" className="style-error" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {t('PROCEDURE.FAILED_TO_DUPLICATE')}
        </Box>
      )}
      {(props?.procedureDetails?.approvalRequestedStatus || props?.procedureDetails?.approvalSentStatus) && (
        <Box component="span" className="style-text style-warning" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {props?.procedureDetails?.approvalSentStatus ? t('PROCEDURE.APPROVAL') + ' ' : ''}
          {props?.procedureDetails?.approvalRequestedStatus || props?.procedureDetails?.approvalSentStatus ? t('PROCEDURE.PENDING') : ''}
        </Box>
      )}
      {props?.procedureDetails?.approvalStatus && (
        <Box component="span" className="style-error" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {t('PROCEDURE.' + props?.procedureDetails?.approvalStatus.toUpperCase())}
        </Box>
      )}
      {props?.procedureDetails?.versionId && (
        <Box component="span" className="style-text" sx={{ ...commonStyles, borderColor: 'grey.500' }}>
          {t('PROCEDURE.CARD_VERSION')} {props?.procedureDetails?.version}{' '}
        </Box>
      )}
      {props?.procedureDetails?.isPublished && (
        <Box component="span" className="style-text style-publish" sx={{ ...commonStyles, borderColor: '#219653' }}>
          {t('PROCEDURE.CARD_PUBLISHED')}
        </Box>
      )}
      {props?.procedureDetails?.documentId && (
        <Box component="span" className="style-text" sx={{ ...commonStyles, borderColor: 'grey.500' }}>
          Document ID: {props?.procedureDetails?.documentId}{' '}
        </Box>
      )}
      {props?.procedureDetails?.lastRevisitedOn + props?.procedureDetails?.revisitExpiry * 60 * 60 * 24 < moment().unix() && !props?.procedureDetails?.revisitComments && props?.procedureDetails?.revisitExpiry && (
        <Box component="span" className="style-text style-warning" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {t('NAVBAR.PERIODIC_REVIEW_PENDING')}
        </Box>
      )}

      {props?.procedureDetails?.lastRevisitedOn + props?.procedureDetails?.revisitExpiry * 60 * 60 * 24 < moment().unix() && props?.procedureDetails?.revisitComments && (
        <Box component="span" className="style-error" sx={{ ...commonStyles, textTransform: 'capitalize'}}>
          {t('NAVBAR.REVISIT_REJECTED')}
        </Box>
      )}
    </>
  );
});

export function openStudioEditorInATab(organizationId: string, workInstructionId: string, procedureId: string, is2D: boolean){
  const studioUrl = environment.AppConfigs.studioURL;
  const query = new URLSearchParams();
  query.append('procedureId', procedureId);
  query.append('model', workInstructionId);
  query.append('org', organizationId);
  if (is2D) {
    query.append('workInstructionType', '2d');
  }
  // query.append('authToken', authToken);
  const url = `${studioUrl}${query.toString()}`;
  window.open(url, '_blank', 'noopener,noreferrer');
};

const CardHeading = (props: {procedureDetails: IProcedures, workInstruction: IWorkInstructions}) => {
  const {procedureDetails, workInstruction}= props;
  const {procedure: procedureQuery} = useParams();
  return (
    <Box component="div" className="trainer-requisits-heading" sx={{
      gap: "8px",
      '& > svg': {
        height: "60px",
        width: '60px'
      }
    }}>
      {procedureDetails.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.REFERENCE ? <TemplateProcedureIcon /> : <NormalProcedureIcon />}
        
      {/* </Box> */}
      <Typography component="div" variant="h5">
        <Typography component="div" variant="h6" sx={{ display: 'block', textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', wordWrap: 'break-word', maxWidth: '22em' }}>
          {procedureDetails.procedureName}
        </Typography>
        <Typography component="div" variant="overline" sx={{ borderColor: 'grey.500', textAlign: 'left', textTransform: 'none' }}>
          {procedureQuery && (
            <Tooltip title={'Work Instruction Name'}>
              <Box component="span" className="style-text" sx={{ ...commonStyles, borderColor: 'grey.500' }}>
                {workInstruction?.workInstructionName}
              </Box>
            </Tooltip>
          )}
          <TaskCount tasks_count={procedureDetails.tasks_count} />
          <ProcedureCardLabels procedureDetails={procedureDetails} />
        </Typography>
      </Typography>
    </Box>
  );
};