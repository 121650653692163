import { IPersona } from '../../entities/Organization/Persona/Persona';
import { newAxiosInstance as http } from '../http-common';
import { DataResponse } from '../service-models';

const getAllPersonas = () => {
  const url = `/persona/get`;
  return http.get<DataResponse<Array<IPersona>>>(url);
};

const getPersona = (personaId: string) => {
  const url = `/persona/get/${personaId}`;
  return http.get<DataResponse<IPersona>>(url);
};

const createPersona = (persona: IPersona) => {
  const url = `/persona/create`;
  return http.post<DataResponse<IPersona>>(url, persona);
};

const updatePersona = (persona: IPersona) => {
  const url = `/persona/edit/${persona.id}`;
  return http.put<DataResponse<IPersona>>(url, persona);
};

const deletePersona = (personaId: string) => {
  const url = `/persona/delete/${personaId}`;
  return http.delete<DataResponse<IPersona>>(url);
};

const PersonaService = {
  getAllPersonas,
  getPersona,
  createPersona,
  updatePersona,
  deletePersona,
};

export default PersonaService;
