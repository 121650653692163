import { Backdrop, Box, Button, CardMedia, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import fallBackIcon from '../../../../assets/fallback_img.svg';
import './LeftProceduresCard.scss';
import { IProcedures, IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import PanToolIcon from '@mui/icons-material/PanTool';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import { calculateTimeInMinutes } from '../../../../shared/utils/helpers';
import { MoreHoriz } from '@mui/icons-material';
import { Delete, Edit } from '../../../Icons/CdsIcons';
import DeleteWorkInstruction from '../../../Trainer/Dialog/DeleteWorkInstruction/DeleteWorkInstruction';
import { getFormattedDateFromUnixTime } from '../../../../shared/utils/formateDate';
import TutorialService from '../../../../services/trainee/TraineeService';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../../shared/enums';
import TourContext from '../../../../context/TourContext';
import { Viewer3D } from 'cds-component-library';
import TrainerService from '../../../../services/trainer/TrainerService';
import { Toast } from '../../../Notifications/Notification';
import { closeSnackbar } from 'notistack';
import { DuplicateWorkInstruction } from '../../../Trainer/Dialog/DuplicateWorkInstructionDialog/DuplicateWorkInstructionDialog';

interface Props {
  brand?: string;
  title?: string;
  description?: string;
  caption?: string;
  id?: string;
  pendingApprovals?: number;
  isTrainer?: boolean;
  status?: null | string;
  isTaskCreated?: boolean;
  showInfoMessage: boolean;
  showErrorMessage: boolean;
  procedures: IProcedures[];
  workInstruction: IWorkInstructions;
  statusLoading?: boolean;
  isDelete?: boolean;
  isRevisitRequest?: boolean;
}

const LeftProceduresCard: React.FC<Props> = (props) => {
  const [contentReady, setContentReady] = React.useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [recycleDialogCheck, setRecycleDialogCheck] = useState<boolean>(false);
  const [deleteDialogCheck, setDeleteDialogCheck] = useState<boolean>(false);
  const [gltfFileReference, setGltfFileReference] = useState<null | string>(null);
  const [gltfFileID, setGltfFileId] = useState<null | string>(null);
  const open = Boolean(anchorEl);
  const [taskCount, setTaskCount] = useState(false);
  const { companyId } = useParams();
  const [workInstruction, setWorkInstruction] = useState<IWorkInstructions>(props.workInstruction);
  const navigate = useNavigate();
  const theme = useTheme();
  const themePrimary = theme.palette.primary.main;
  const { isDeletedWorkInstructionBackButton } = useContext(TourContext);
  const themePrimaryExtraLight = theme.palette.primary.main + '0d';
  const [bearerToken, setBearerToken] = useState<string>('');
  const [showDuplicateWorkInstructionDialog, setShowDuplicateWorkInstructionDialog] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = React.useState<any>('');
  const commonStyles = {
    border: 1,
    // borderColor: 'text.primary',
  };

  // useEffect(() => {
  //     if (props.workInstruction.procedures.length > 0 && props.procedures.length > 0 && props.procedures[0]?.tasks_count > 0) {
  //         TutorialService.getTasks(props.workInstruction.workInstructionId, props.workInstruction.procedures[0], null).then((e) => {
  //             setGltfFileReference(e.data.data?.fileInfo?.cad?.models[0]?.gltf_path)
  //         })
  //     }
  // }, [props.workInstruction, props.procedures])

  useEffect(() => {
    if (!gltfFileReference && props.workInstruction && !props.workInstruction.is2d && props.workInstruction?.workInstructionId && props.workInstruction?.fileStatus?.toUpperCase() === 'SUCCESS') {
      // Load work instruction model instead of procedure model
      FileManagerService.getWorkInstructionGltfFileId(props.workInstruction.workInstructionId).then((e) => {
        if (e.data && e.data[0]) {
          const { id } = e.data[0];
          setGltfFileId(id);
          FileManagerService.getFileById(id).then((res) => {
            const blob = new Blob([res.data]);
            const url = URL.createObjectURL(blob);
            setGltfFileReference(url);
          });
        }
      });
    }
    if (props.workInstruction.is2d) {
      FileManagerService.getWorkInstructionThumbnail2d(localStorage.getItem('organizationId') || '', props.workInstruction.workInstructionId)
        .then((res) => {
          console.log(res, 'sada');
          getThumbnailImage((res as any).data[0].id);
        })
        .catch((e) => {
          setThumbnail('');
        });
    }
  }, [props.workInstruction]);

  const getThumbnailImage = (workInstructionImageURL) => {
    if (workInstructionImageURL){
      FileManagerService.getWorkInstructionThumbnailPath(workInstructionImageURL,false)
        .then((res) => {
          const blob = new Blob([res.data]);
          const url = URL.createObjectURL(blob);
          setThumbnail(url);
        })
        .catch((err) => {
          console.log(err);
        });
      }
 
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const recycleClickHandler = () => {
    setAnchorEl(null);
    setShowDeleteDialog(true);
    setRecycleDialogCheck(true);
  };

  const deleteClickHandler = () => {
    setAnchorEl(null);
    setShowDeleteDialog(true);
    setDeleteDialogCheck(true);
  };

  const restoreWI = () => {
    setContentReady(false);
    TutorialService.restoreBinProcedures(props.workInstruction.workInstructionId)
      .then((response) => {
        navigate(`/trainer/recycleBin/${companyId}`);
      })
      .finally(() => setContentReady(true));
  };

  const closeDeleteDialogHandler = () => {
    setShowDeleteDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.isTaskCreated === true) {
      setTaskCount(true);
    }
  }, [props.status]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setBearerToken(token);
    }
  }, []);
  const getProceduresEstimatedTime = () => {
    let estimatedTime = 0;

    if (props.procedures && props.procedures.length > 0) {
      estimatedTime = props.procedures.reduce((acc, { estimated_time }) => acc + estimated_time, 0);
    }

    return estimatedTime;
  };

  const editWorkInstructionHandler = () => {
    navigate(`/trainer/editWorkInstruction/${props.id}`);
  };

  const duplicateWorkInstructionHandler = () => {
    setAnchorEl(null);
    setShowDuplicateWorkInstructionDialog(true);
  };

  const closeDuplicateWorkInstructionDialogHandler = () => {
    setShowDuplicateWorkInstructionDialog(false);
  };

  const getMessageStyle = () => {
    let msgStyle = '';

    switch (props.status) {
      case '3D Model Ready':
        msgStyle = 'style-success';
        break;
      case 'Preparing 3D Model':
        msgStyle = 'style-warning';
        break;
      case '3D Model Conversion Failed':
      case 'CAD File Upload Failed':
        msgStyle = 'style-error';
        break;
      default:
        msgStyle = 'style-error';
        break;
    }

    return msgStyle;
  };

  const getMessage = () => {
    let message = 'Preparing 3D Model';
    if (props.status) {
      message = props.status;
    }
    return message;
  };

  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      {<DuplicateWorkInstruction openDialog={showDuplicateWorkInstructionDialog} closeDialog={closeDuplicateWorkInstructionDialogHandler} workInstruction={props.workInstruction} />}
      <Box sx={{ borderTopLeftRadius: 12, borderBottomLeftRadius: 12, border: { xs: 'none' }, boxShadow: { xs: 'none' }, backgroundColor: { xs: 'none', md: themePrimaryExtraLight } }} component="div" className={`left-container ${props.isTrainer ? 'trainer-card' : 'trainee-card'}`}>
        {props.isTrainer && <DeleteWorkInstruction openDialog={showDeleteDialog} closeDialog={closeDeleteDialogHandler} id={props.id} isWorkinstruction={true} isDeletePermanently={deleteDialogCheck} isRecycle={recycleDialogCheck} />}

        {/*<Stack direction="row" spacing={2}>
               {props.status && (props.showErrorMessage || props.showInfoMessage || props.status === '3D Model Ready') && <Box component="span" className={'style-warning'} sx={{ ...commonStyles }}>{props.status}</Box>}

    </Stack>*/}

        {props.isTrainer && !props.isDelete && (
          <Stack direction="row" className="procedure-status-stack">
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row' }}>
              {!props.workInstruction.is2d && (
                <>
                  {!props.statusLoading ? (
                    <Box component="div" className={getMessageStyle()} sx={{ ...commonStyles }}>
                      {t('COMMON.' + translate[getMessage().toLocaleUpperCase()])}
                    </Box>
                  ) : (
                    <Skeleton sx={{ height: '36px', width: '100%' }} />
                  )}
                </>
              )}

              {props.workInstruction.is2d && (
                <Box component="div" className="style-success" sx={{ ...commonStyles }}>
                  {t('PROCEDURE.2D_MODEL_READY')}
                </Box>
              )}

              {!props.isRevisitRequest && props.pendingApprovals !== undefined && props.pendingApprovals > 0 && (
                <Box component="div" className="style-warning" sx={{ ...commonStyles }}>
                  {props.pendingApprovals} {props.pendingApprovals > 1 ? t('PROCEDURE.APPROVALS_PENDING') : t('PROCEDURE.APPROVAL_PENDING')}{' '}
                </Box>
              )}
            </Box>
            {!props.isRevisitRequest && (
              <>
                <IconButton aria-label="delete" color="primary" id="demo-positioned-button" aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                  <MoreHoriz fontSize="small" />
                </IconButton>
                <Menu color="primary" id="demo-positioned-menu" aria-labelledby="demo-positioned-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                  {props.workInstruction.is2d && (
                    <MenuItem onClick={duplicateWorkInstructionHandler}>
                      <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={themePrimary}>
                          <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
                        </svg>
                      </SvgIcon>
                      &nbsp;{t('COMMON.DUPLICATE_WORK_INSTRUCTION')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={editWorkInstructionHandler}>
                    <Edit />
                    &nbsp;{t('PROCEDURE.EDIT_WORK_INSTRUCTION')}
                  </MenuItem>
                  <MenuItem onClick={recycleClickHandler}>
                    <Delete />
                    &nbsp;{t('PROCEDURE.DELETE_WORK_INSTRUCTION')}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        )}
        {!contentReady && (
          <Box component="div" className="align-loader">
            <Loading />
          </Box>
        )}
        <Stack direction="row" className="procedure-status-stack">
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem', maxWidth: '100%' }}>
              <Grid container sx={{ alignItems: 'center', maxWidth: '100%' }}>
                {props.isTrainer && !props.isDelete && (
                  <Grid item xs={12}>
                    <Typography component="div" variant="h5" title={props.title} className={`left-margin left-card-one-line-ellipsis ${!props.isTrainer ? 'trainee-title' : ''}`} sx={{ lineHeight: '1.2', fontSize: '1.25rem' }}>
                      {props.title && props.title}
                    </Typography>
                  </Grid>
                )}

                {props.isTrainer && props.isDelete && !isDeletedWorkInstructionBackButton && (
                  <Grid item xs={12}>
                    <Typography component="div" variant="h5" title={props.title} className={`left-margin left-card-one-line-ellipsis ${!props.isTrainer ? 'trainee-title' : ''}`} sx={{ lineHeight: '1.2', fontSize: '1.25rem' }}>
                      {props.title && props.title}
                    </Typography>
                  </Grid>
                )}

                {props.isTrainer && props.isDelete && isDeletedWorkInstructionBackButton && (
                  <>
                    <Grid item xs={4}>
                      <Typography component="div" variant="h5" title={props.title} className={`left-margin left-card-one-line-ellipsis ${!props.isTrainer ? 'trainee-title' : ''}`} sx={{ lineHeight: '1.2', fontSize: '1.25rem' }}>
                        {props.title && props.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex' }}>
                      <>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          disableRipple
                          sx={{
                            margin: '0px 8px 0px 0px',
                            borderRadius: '8px',
                            p: 0,
                            fontSize: '16px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: 'fit-content',
                          }}
                          onClick={restoreWI}
                        >
                          &nbsp;{t('BUTTON.RESTORE')}
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          disableRipple
                          sx={{
                            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
                            m: 0,
                            p: 0,
                            fontSize: '16px',
                            overflow: 'hidden',
                            height: 'fit-content',
                            textOverflow: 'ellipsis',
                          }}
                          onClick={deleteClickHandler}
                        >
                          &nbsp;{t('BUTTON.DELETE')}
                        </Button>
                      </>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Typography variant="subtitle2" title={props.description} color="text.secondary" component="div" className="left-margin left-card-multi-line-ellipsis" sx={{ width: '18rem', lineHeight: '1rem' }}>
              {props.description && props.description}
            </Typography>
            {!props.isDelete && props.isTrainer && (
              <Typography variant="subtitle2" title={props.description} color="text.secondary" component="div" className="left-margin left-card-multi-line-ellipsis" sx={{ width: '18rem', lineHeight: '1rem' }}>
                {getFormattedDateFromUnixTime(workInstruction?.createdOn)}
              </Typography>
            )}

            <Typography variant="subtitle2" title={props.brand} color="text.secondary" component="div" className="left-margin left-card-multi-line-ellipsis" sx={{ width: '18rem', lineHeight: '1.2', fontSize: '0.75rem', marginTop: '0.8rem' }}>
              {props.brand && props.brand}
            </Typography>
          </Box>
        </Stack>

        <Box component="div" className="image-container">
          {!gltfFileReference && <CardMedia component="img" className="default-image" image={thumbnail?thumbnail:fallBackIcon}></CardMedia>}
          {/* TODO will be taken care once we have image {workImage &&
                  <img src={workImage} />
              } */}
          {gltfFileReference && gltfFileID && (
            <Suspense fallback={<Loading />}>
              {/* <MiniCanvas gltfFilePath={gltfFileReference} /> */}
              <Box component={'div'} sx={{ height: '70vh', width: '100%' }}>
                <Viewer3D bearerToken={bearerToken} cdsModelId={gltfFileID} isPrimitive autoRotate />
              </Box>
            </Suspense>
          )}
        </Box>
        {!props.isTrainer && workInstruction && (
          <Box component="div" className="info-container">
            <Box component="div" className="sub-info-container">
              <Typography variant="button" color="text.secondary" component="div" className="work-ins-text" sx={{ fontSize: '0.75rem' }}>
                {t('PROCEDURE.ADDED_ON')}
              </Typography>
              <Typography variant="body1" color="text.secondary" component="div" className="work-ins-text-bottom" sx={{ fontSize: '0.75rem' }}>
                <span>{getFormattedDateFromUnixTime(workInstruction?.createdOn)}</span>
              </Typography>
            </Box>
            <Box component="div" className="sub-info-container">
              <Typography variant="button" color="text.secondary" component="div" className="work-ins-text" sx={{ fontSize: '0.75rem' }}>
                {t('PROCEDURE.EST_TIME')}
              </Typography>
              <Typography variant="body1" color="text.secondary" component="div" className="work-ins-text-bottom" sx={{ fontSize: '0.75rem' }}>
                {props.procedures && props.procedures?.length > 0 && calculateTimeInMinutes(getProceduresEstimatedTime())} min
              </Typography>
            </Box>
            <Box component="div" className="sub-info-container">
              <Typography variant="button" color="text.secondary" component="div" className="work-ins-text" sx={{ fontSize: '0.75rem' }}>
                {t('PROCEDURE.COMPLETED_IN')}
              </Typography>
              <Typography variant="body1" color="text.secondary" component="div" className="work-ins-text-bottom" sx={{ fontSize: '0.75rem' }}>
                {workInstruction?.completionStatus?.toUpperCase() === 'COMPLETED' && workInstruction?.completionTime ? calculateTimeInMinutes(workInstruction?.completionTime) : '--:--'} {t('PROCEDURE.MINS')}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LeftProceduresCard;
