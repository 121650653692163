import { Backdrop, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';
import { closeSnackbar } from 'notistack';
import { Toast } from '../../../Notifications/Notification';

export const DuplicateWorkInstruction = (props) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();

  const cloneProcedure = async () => {

    Toast.info(`Work Instruction duplication is in progress`, `A new ${props.workInstruction.workInstructionName + ' - Copy'} is getting created`, true);
    const payload = {
      workInstructionId: props.workInstruction.workInstructionId,
    };

    TrainerService.cloneWorkInstruction(payload)
      .then((response) => {
        closeSnackbar();
        Toast.success(`Work Instruction  duplication completed`, `Please check ${props.workInstruction.workInstructionName + ' - Copy'}`);
      })
      .catch(({ response }) => {
        closeSnackbar();
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={t('COMMON.DUPLICATE_WORK_INSTRUCTION')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                props.closeDialog();
              }}
              variant="outlined"
              size="small"
              type="submit"
            >
              {t('BUTTON.CANCEL') as string}
            </Button>
            <Button
              onClick={() => {
                cloneProcedure();
              }}
              variant="contained"
              size="small"
              type="submit"
            >
              {t('BUTTON.CONFIRM') as string}
            </Button>
          </>
        }
      >
        {t('PROCEDURE.ARE_YOU_SURE_DUPLICATE_WORK_INSTRUCTION')}
      </BasicDialog>
    </>
  );
};
