import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useTranslation } from 'react-i18next';
import { IDateRange } from '../../../entities/Analytics/AnalyticsProcedure';
import { SelectionType } from '../../../states/analytics';
import { Feedback } from '../ProcedureReports/Feedback';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

interface ITraineesAssignedProps {
  dateRange: IDateRange;
  filterType: SelectionType;
}

const AverageTimeInStudio: React.FC<ITraineesAssignedProps> = ({ dateRange, filterType }) => {
  const { t } = useTranslation();

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box component="div" sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', mb: '24px', maxHeight: '50px' }}>
        <Typography sx={{ fontSize: '14px' }}>Average Procedure Creation Time</Typography>

        <ViewInArIcon sx={{ fontSize: '30px', color: 'primary.main' }} />
      </Box>

      <Box
        component="div"
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        No Data Available
      </Box>
    </Box>
  );
};

export default AverageTimeInStudio;
