import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DemoIcon } from '../../Icons/CdsIcons';
import useTour from '../../../hooks/tour';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function lighten(color, amount) {
  // Convert the color to an RGB array
  const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  const r = parseInt(rgb[1], 16);
  const g = parseInt(rgb[2], 16);
  const b = parseInt(rgb[3], 16);

  // Calculate the lighter RGB values
  const max = 255;
  const newR = Math.round(r + (max - r) * amount);
  const newG = Math.round(g + (max - g) * amount);
  const newB = Math.round(b + (max - b) * amount);

  // Convert the lighter RGB values back to a hex color code
  const newColor = `#${newR.toString(16)}${newG.toString(16)}${newB.toString(16)}`;
  return newColor;
}
interface Props {
  overview?: string;
}
const TourCard = (props:Props) => {
  const { isTourOpen, setIsTourOpen, tourStep, setTourStep, setTourTitle } = useTour();
  const theme = useTheme();
 

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        display: 'flex',
        borderRadius: '12px',
        height: props.overview === 'extended_view' ? '144px' : '280px',
        // width: props.overview === 'extended_view' ? '256px' : '400px',
        flexGrow: 1,
        background: `linear-gradient(90deg, ${lighten(theme.palette.primary.main, 0.2)} 0%, ${theme.palette.primary.main} 98.9%)`,
        boxShadow: ' 0px 0px 40px rgba(26, 40, 53, 0.2)',
        color: 'white',
        flexDirection: props.overview === 'extended_view' ? 'row' : 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        cursor: 'pointer',
      }}
      elevation={0}
      onClick={() => {
        setIsTourOpen(true);
        setTourStep(0);
        navigate('/trainer/addWorkInstruction');
        setTourTitle('First work instruction & procedure setup');
      }}
      className="work-instruction-card"
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: props.overview === 'extended_view' ? 'row' : 'column',
          justifyContent: 'space-between',
          margin: props?.overview==='extended_view'?'12px':'24px',
          textAlign: 'start',
          gap: '10px',
        }}
      >
        <DemoIcon />
     
        

        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'start',
          }}
        >
          <Typography sx={{ fontSize: props?.overview==='extended_view'?'14px':'20px' }}>{t('WI_LIST.LEARN_HOW_TO_CREATE_WI')}</Typography>
          <Typography
            sx={{
              margin: props.overview==='extended_view'?'4px 0px 7px 0px':'8px 0px 13px 0px',
              fontSize: props.overview==='extended_view'?'12px':'14px',
              opacity: 0.7,
            }}
          >
            {t('WI_LIST.DEMO_WALKTHROUGH')}
          </Typography>
          <Typography sx={{ display: 'flex',fontSize: props.overview==='extended_view'?'12px':null, gap: '5px'}}>
            {t('WI_LIST.LEARN')} <ArrowForwardIcon sx={{ fontSize: props.overview==='extended_view'?'14px':null, color: 'white' }} />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default TourCard;
