import { CompanyTheme, Feedback, IProcedures, IUserStats, IUserStatsFinish, IUserStatsNext, IWorkInstructions } from '../../entities/Trainee/MockTrainee';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../shared/enums';
import http, { partnerInstance } from '../http-common';
import { DataResponse } from '../service-models';

export const moveToLibrary = (procedureId: string) => {
  if(!procedureId) return Promise.reject("No procedure id");
  let url = `/procedures/library/createFrom/${procedureId}`;
  return http.post<DataResponse<any>>(url);
}

interface ImportFromLibraryPayload {
  visibilityScope: PROCEDURE_VISIBILITY_SCOPE,
  referencedProcedure: string,
  isPrerequisite: boolean,
  workInstructionId: string,
  procedureId?: string
}
export const importFromLibrary = (payload: ImportFromLibraryPayload) => {
  if(!payload) return Promise.reject("No Payload");
  let url = `/procedures/createProcedure`;
  return http.post<DataResponse<IWorkInstructions>>(url, payload);
}
export const getTemplateProcedures = (queryParams?: string) => {
    let instance = http;
    let url = '/procedures/library/list';
    if(queryParams){
      url += '?' + queryParams
    }
    // if (accessToken) {
    //   instance = partnerInstance(accessToken);
    //   url = '/partnerapps/procedure';
    // } else {
    //   url = `/procedures/getProcedure/${procId}`;
    // }
    return instance.get<any>(url);
  };

export const getLinkedWorkInstruction = (procedureId: string) => {
  let instance = http;
  let url = '/procedures/library/linkedProcedures/'+procedureId;

  return instance.get<any>(url);
}