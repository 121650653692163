import { ILanguageData } from "../../entities/Languages/Languages";
import {newAxiosInstance as http} from "../http-common"

const getLanguages = () => {
  return http.get(`/languages`);
}

const updateLanguage = (languageData: ILanguageData) => {
    return http.put(
      `/languages`,
      languageData
    );
}

const LanguageService = {
    getLanguages,
    updateLanguage,
}
export default LanguageService
