import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import './MediaUploadDialog.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import Loading from '../../../Loading/Loading';
import { Autocomplete, TextField, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { mediaType } from '../../../../entities/Trainee/WorkInstructions';
import { Buffer } from 'buffer';
import { Vector3 } from 'three';
import MediaRef from './MediaRef';
import { createObjectUrlFromDataUrl } from '../../../../shared/utils/mediaUtilities';
import { convertFileBlobToMediaType } from '../../../../services/Mapper/Mapper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div" sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      minWidth: '400px', // Set your width here
    },
  },
  '& .MuiDialogContent-root': {
    paddingBottom: '0px',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px 24px',
  },
  '& .MuiDialogTitle-root': {
    margin: ' 12px 12px 0px 12px !important',
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  procedure: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="remove-margin" sx={{ m: 4, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const isString = (item: any): item is string => {
  return typeof item === 'string';
};

type AutoCompleteFieldProps<T> = {
  selectValue: keyof T;
  options: T[];
  onChange: any;
};

const AutoCompleteField = <T extends {}>({ selectValue, options, onChange }: AutoCompleteFieldProps<T>): React.ReactElement => {
  return (
    <Autocomplete
      id="manager-auto-complete"
      options={options}
      getOptionLabel={(option: any) => {
        const val = option[selectValue];
        return isString(val) ? val : '';
      }}
      onChange={onChange}
      renderInput={(params) => (
        <Box component="div">
          <TextField {...params} placeholder="Enter Here" />
        </Box>
      )}
    />
  );
};

export default function MediaUploadDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [PpeName, setPpeName] = React.useState('');
  const [PpeImage, setPpeImage] = React.useState();
  const [fileValues, setFileValues] = React.useState<any>();
  const [localFiles, setLocalFiles] = React.useState<File[]>([]);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let files = e.target.files;
    
    if(!files) return;
    let filesToSet = Array.from(files).map(file => file);
    setLocalFiles([...localFiles, ...filesToSet]);
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>){
    e.preventDefault();
    let items = e.dataTransfer.items;
    if(!items) return;
    let filesToSet: File[] = [];
    Array.from(items).forEach(item => {
        if(item.kind === 'file'){
          const file: File | null = item.getAsFile();
          if(!file) return;
          filesToSet.push(file);
        }
    });
    setLocalFiles([...localFiles, ...filesToSet]);
  }

  function startFileUpload(e) {
    let fileElement = document.getElementById('fileLoaderElement');
    if (fileElement) {
      fileElement.click();
    }
  }

  const handleSaveFile = () => {
    const updatedFiles = [...props.selectedFiles, ...localFiles];
    props.setSelectedFiles(updatedFiles);
    props.closeDialog();
    setOpen(false);
  };

  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openDialog}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '714px', 
              maxWidth: 'none', 
            },
          },
        }}
      >
        {(props.loading || loading) && <Loading />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.editMode ? (t('COMMON.EDIT_PPE_EQUIPMENT') as string) : (t('COMMON.MEDIA_UPLOAD') as string)}
        </BootstrapDialogTitle>

        <DialogContent>
          <Box component={'div'} sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Input onChange={handleInputChange} inputProps={{ accept: 'image/png,image/jpg,image/jpeg,video/mp4,video/quicktime,.pdf', multiple: true }} id="fileLoaderElement" type="file" sx={{ display: 'none' }}></Input>
            <Box
              onClick={(e) => startFileUpload(e)}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={handleDrop}
              component={'div'}
              sx={{ backgroundColor: '#ededed', padding: '20px', color: '#999999', zIndex: 10, cursor: 'pointer', justifyContent: 'center', textAlign: 'center', width: '100%' }}
            >
              <UploadFileIcon />
              <Typography>{t('ADD_WI.UPLOAD_FILE_MESSAGE')}</Typography>
            </Box>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', margin: '8px 8px 8px 8px' }}>
              <Typography sx={{ fontSize: '10px', color: '#6b6b6b', textAlign: 'right' }}>{t('COMMON.FILE_SIZE_LIMIT')}: 500MB</Typography>
              <Typography sx={{ fontSize: '10px', color: '#6b6b6b', textAlign: 'right' }}>{t('MEDIA.SUPPORTS')}: “.png”, “.jpeg, .pdf”, “.mp4”,“.mov”</Typography>
            </Box>

            <UploadFilePreview localFiles={localFiles} setLocalFiles={setLocalFiles}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!localFiles} variant="contained" type="submit" onClick={handleSaveFile}>
            {t('BUTTON.SAVE') as string}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}


const UploadFilePreview = (props) => {
  const { localFiles, setLocalFiles }:{localFiles: File[], setLocalFiles: React.Dispatch<React.SetStateAction<File[]>>} = props;
  const [filesToPreview, setFilesToPreview] = React.useState<mediaType[]>([]);

  React.useEffect(() => {
    const mediaTypeArray: mediaType[] = localFiles.map(file => convertFileBlobToMediaType(file));
    setFilesToPreview(mediaTypeArray);
  }, [localFiles])

  function clearLocalFiles(index: any) {
    setLocalFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }

  return <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
  {filesToPreview.map((file, index) => (
    <>
      {file && file.dataUrl && !file.objectUrl && <MediaRef key={index} aReference={file} index={index} />}
      {file && file.objectUrl && <MediaRef key={index} aReference={file} index={index} onDelete={clearLocalFiles} />}
    </>
  ))}
</Box>
}