import { Button, Typography } from '@mui/material';
import { BasicDialog } from '../BasicDialog/BasicDialog';

export const RevokeConfirmationDialog = (props) => {
  return (
    <BasicDialog
      title={'Remove Access'}
      onClose={() => {
        props.closeDialog();
      }}
      onOpen={props.openDialog}
      actions={
        <>
          <Button
            onClick={() => {
              props.closeDialog();
            }}
            variant="outlined"
            size="small"
            type="submit"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.revokeAssignees();
              props.closeDialog();
            }}
            variant="contained"
            size="small"
            type="submit"
          >
            Remove
          </Button>
        </>
      }
    >
      <Typography>Once removed all the data on the trainees end will also be deleted. Are you sure you want to remove access for these trainees?</Typography>
    </BasicDialog>
  );
};
