import { Box, Grid, Skeleton, Typography, Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDateRange, IOrganizationNewlyAdded } from '../../entities/Analytics/AnalyticsProcedure';
import AnalyticsService from '../../services/Analytics/AnalyticsService';
import { DATE_FORMAT } from '../../shared/utils/const-helpers';
import { useAnalytics } from '../../states/analytics';
import { Toast } from '../Notifications/Notification';
import './Analytics.scss';
import Header from './Header';
import AssignedProcedures from './ProcedureReports/AssignedProcedures';
import PendingProcedures from './ProcedureReports/PendingProcedures';
import TraineesAssigned from './TraineeReports/TraineesAssigned';
import TrendingCount from './TrendingCount';
import UsageReport from './UsageReport';
import { Feedback } from './ProcedureReports/Feedback';
import { ProceduresAssigned } from './TraineeReports/TraineesAssigned/TraineeAssignedProceduresPieChart';
import { AssignmentHistory } from './TraineeReports/TraineesAssigned/AssignmentHistory';
import OrganizationOverview from './TrainerReports/OrganizationOverview';
import AverageTimeInStudio from './TrainerReports/AverageTimeInStudio';
import { ActionRequired } from './TrainerReports/ActionRequired';
import { AnalyticsTooltip } from './ProcedureReports/AnalyticsTooltip';
import { TrainingSignOff } from './TrainingReports/TrainingSignOff';

const TrainerOverview = ({ loadingData, LoadersState, wiOverview, procedureOverview, procedurePublishOverview, trainerUserOverview, myOverview, currentDateRange, currentFilterType, pendingProcedures }) => {
  return (
    <>
      <Typography sx={{ fontSize: '14px', display: 'flex', mb: '12px', justifyContent: 'flex-start' }}>ORGANIZATION OVERVIEW</Typography>
      <Box component="div" className="analytics-box" sx={{ display: 'flex', height: '261px' }}>
        <Grid container spacing={2} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
              {loadingData[LoadersState.TRAINER_ORG_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.TRAINER_ORG_OVERVIEW] === false && wiOverview && <OrganizationOverview chartData={wiOverview} title="Work Instructions" />}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
              {loadingData[LoadersState.TRAINER_ORG_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.TRAINER_ORG_OVERVIEW] === false && procedureOverview && <OrganizationOverview chartData={procedureOverview} title="Procedures" />}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
              {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === false && wiOverview && <OrganizationOverview chartData={procedurePublishOverview} title="Procedures  Published" />}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography sx={{ fontSize: '14px', display: 'flex', mb: '12px', mt: '55px', justifyContent: 'flex-start' }}>MY OVERVIEW</Typography>
      <Box component="div" className="analytics-box" sx={{ display: 'flex', height: '570px' }}>
        <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1, backgroundColor: 'rgba(255, 255, 255, 0.2)', overflowY: 'hidden' }}>
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1 }}>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === false && myOverview && <Feedback title="Work Ins Created" value={trainerUserOverview?.user?.newWorkInstructions?.length || 0} />}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: 'fit-content' }}>
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === false && myOverview && <Feedback title="Procedures Created" value={trainerUserOverview?.user?.newlyCreatedProcedures || 0} />}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.TRAINER_USER_OVERVIEW] === false && myOverview && <Feedback title="Procedures Published" value={trainerUserOverview?.user?.newlyPublished || 0} />}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, mt: '16px' }}>
              <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                {false && (
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Skeleton variant="rectangular" height={'50px'} width="200px" />
                    <br />
                    <Skeleton variant="rectangular" height={'100%'} width="100%" />
                  </Box>
                )}
                {true && <AverageTimeInStudio dateRange={currentDateRange} filterType={currentFilterType} />}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ height: '100%' }}>
            <Box component="div" className="analytics-box" sx={{ height: 'calc(100% - 50px)', overflow: 'auto', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              {loadingData[LoadersState.PENDING_PROCEDURES] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.PENDING_PROCEDURES] === false && pendingProcedures && <ActionRequired dateRange={currentDateRange} filterType={currentFilterType} procedureState={pendingProcedures} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const TraineeOverview = ({ loadingData, LoadersState, currentDateRange, currentFilterType, pendingProcedures, startDate, endDate, assignedTraineesData, completionRate, assignmentHistory, filter }) => {
  const navigate = useNavigate();

  const handleGotoAllPendingProceduresList = () => {
    navigate(`/analytics/procedures/pending?filter=${currentFilterType}&startDate=${currentDateRange.startDate}&endDate=${currentDateRange.endDate}&procedure=All&procedureFilter=UNASSIGNED`);
  };

  return (
    <>
      <Typography sx={{ fontSize: '14px', display: 'flex', mb: '12px', justifyContent: 'flex-start' }}>ORGANIZATION OVERVIEW</Typography>
      <Box component="div" className="analytics-box" sx={{ display: 'flex', height: '570px' }}>
        <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1, backgroundColor: 'rgba(255, 255, 255, 0.2)', overflowY: 'hidden' }}>
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} sx={{ display: 'flex', flexGrow: 1 }}>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px', cursor: 'pointer', alignItems: 'flex-start', display: 'flex' }}>
                  {loadingData[LoadersState.ASSIGNED_TRAINEES] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.ASSIGNED_TRAINEES] === false && assignedTraineesData && (
                    <>
                      <AnalyticsTooltip label={'Click to see who have completed the procedure'}>
                        <Button
                          sx={{ padding: 0, color: '#004b8d' }}
                          onClick={() => {
                            navigate(`/analytics/trainees/completionRate/details?startDate=${currentDateRange.startDate || startDate}&endDate=${currentDateRange.endDate || endDate}&filter=${currentFilterType || filter}`);
                          }}
                        >
                          <Feedback title="Completion Rate" value={completionRate} />
                        </Button>
                      </AnalyticsTooltip>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: 'fit-content' }}>
                  {loadingData[LoadersState.ASSIGNMENT_HISTORY] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.ASSIGNMENT_HISTORY] === false && assignmentHistory && <Feedback title="Procedures Assigned" value={assignmentHistory.length || '0'} />}
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex' }}>
                <Box component="div" className="analytics-box" sx={{ flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '71px' }}>
                  {loadingData[LoadersState.PENDING_PROCEDURES] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
                  {loadingData[LoadersState.PENDING_PROCEDURES] === false && pendingProcedures && (
                    <Button
                      sx={{ padding: 0, color: '#004b8d', display: 'flex' }}
                      onClick={() => {
                        handleGotoAllPendingProceduresList();
                      }}
                    >
                      <Feedback title="Procedures Ready for Assignment" value={pendingProcedures.pendingCounts.unassigned || 0} />
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1, mt: '16px' }}>
              <Box component="div" className="analytics-box" sx={{ display: 'flex', flexGrow: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                {loadingData[LoadersState.ASSIGNED_TRAINEES] === true && (
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Skeleton variant="rectangular" height={'50px'} width="200px" />
                    <br />
                    <Skeleton variant="rectangular" height={'100%'} width="100%" />
                  </Box>
                )}
                {loadingData[LoadersState.ASSIGNED_TRAINEES] === false && assignedTraineesData && <TraineesAssigned dateRange={currentDateRange} filterType={currentFilterType} assignedTraineesData={assignedTraineesData} />}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ height: '100%' }}>
            <Box component="div" className="analytics-box" sx={{ height: 'calc(100% - 50px)', overflow: 'auto', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              {loadingData[LoadersState.ASSIGNMENT_HISTORY] === true && <Skeleton variant="rectangular" height={'100%'} width="100%" />}
              {loadingData[LoadersState.ASSIGNMENT_HISTORY] === false && assignmentHistory && <AssignmentHistory assignmentHistory={assignmentHistory} />}
              {assignmentHistory && assignmentHistory.length === 0 && <Typography sx={{ fontSize: '14px', mt: '24px', color: 'rgba(18, 24, 31, 0.6)' }}>[No procedures have been assigned]</Typography>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const TrainingOverview = () => {
  return (
    <>
      <TrainingSignOff />
    </>
  );
};

const AnalyticsContainer = () => {
  enum LoadersState {
    ASSIGNED_TRAINEES = 'ASSIGNED_TRAINEES',
    ASSIGNMENT_HISTORY = 'ASSIGNMENT_HISTORY',
    PENDING_PROCEDURES = 'PENDING_PROCEDURES',
    TRAINER_ORG_OVERVIEW = 'TRAINER_ORG_OVERVIEW',
    TRAINER_USER_OVERVIEW = 'TRAINER_USER_OVERVIEW',
  }
  interface IChartData {
    name: string;
    value: number;
    color?: string;
  }
  type LoaderStateType = { [key in LoadersState]: boolean };
  const currentDateRange = useAnalytics((state) => state.selectedDateRange);
  const currentFilterType = useAnalytics((state) => state.selectedType);
  const [selectedFilter, setSelectedFilter] = useState<IDateRange>();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState<LoaderStateType>({
    [LoadersState.ASSIGNED_TRAINEES]: true,
    [LoadersState.ASSIGNMENT_HISTORY]: true,
    [LoadersState.PENDING_PROCEDURES]: true,
    [LoadersState.TRAINER_ORG_OVERVIEW]: true,
    [LoadersState.TRAINER_USER_OVERVIEW]: true,
  });
  const [summaryData, setSummaryData] = useState<IOrganizationNewlyAdded>();
  const [overview, setOverview] = useState('trainer');
  const [assignedTraineesData, setAssignedTraineesData] = useState<any>();
  const [assignmentHistory, setAssignmentHistory] = useState<any>();
  const saveAssignedTraineesList = useAnalytics((state) => state.setAssignedTraineesList);
  const saveAssignmentHistory = useAnalytics((state) => state.setAssignmentHistory);
  const [pendingProcedures, setPendingProcedures] = useState<any>();
  const [completionRate, setCompletionRate] = useState('');
  const [trainerUserOverview, setTrainerUserOverview] = useState<any>();

  const [wiOverview, setWiOverview] = useState<Array<IChartData>>();
  const [procedureOverview, setProcedureOverview] = useState<Array<IChartData>>();
  const [procedurePublishOverview, setProcedurePublishOverview] = useState<Array<IChartData>>();
  const [myOverview, setMyOverview] = useState<any>();

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const filterType = query.get('filter');
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const setLoadingDataState = (key: keyof typeof LoadersState, value: boolean) => {
    setLoadingData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (currentDateRange) {
      loadData(currentDateRange);
    } else if (startDate && endDate) {
      loadData({
        startDate: startDate,
        endDate: endDate,
      } as IDateRange);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (currentDateRange) {
      loadData(currentDateRange);
    }
  }, [currentDateRange, currentFilterType, filterType, startDate, endDate]);

  const loadData = (dateRange: IDateRange) => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      getSummary(dateRange);
      getAssignedTrainees(dateRange);
      getAssignmentHistory(dateRange);
      getPendingProcedures(dateRange);
      getTrainerUserOverview(dateRange);
    }
  };

  const savePendingProcedureList = useAnalytics((state) => state.setPendingProceduresList);

  const getPendingProcedures = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.PENDING_PROCEDURES, true);
    AnalyticsService.getAnalyticsOrganizationPendingProcedures('Mine', dateRange.startDate, dateRange.endDate)
      .then((e) => {
        setPendingProcedures(e.data.data);
        savePendingProcedureList(e.data.data);
        setLoadingDataState(LoadersState.PENDING_PROCEDURES, false);
      })
      .catch(({ response }) => {
        setLoadingDataState(LoadersState.PENDING_PROCEDURES, false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getTrainerUserOverview = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.TRAINER_USER_OVERVIEW, true);
    AnalyticsService.getAnalyticsTrainerAndUserOverview(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        setTrainerUserOverview(e.data.data);
        setProcedurePublishOverview([
          {
            name: 'Published',
            value: e.data.data.organization.newlyPublished || 0,
            //color: '#C4B454',
            color: '#53479D',
          },
          {
            name: 'Unpublished',
            value: e.data.data.organization.newlyCreatedProcedures - e.data.data.organization.newlyPublished || 0,
            color: '#C1ADDE',
          },
        ]);
        setLoadingDataState(LoadersState.TRAINER_USER_OVERVIEW, false);
      })
      .catch(({ response }) => {
        setLoadingDataState(LoadersState.TRAINER_USER_OVERVIEW, false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getAssignmentHistory = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, true);
    AnalyticsService.getAnalyticsOrganizationAssignedProceduresList(dateRange.startDate || moment().clone().startOf('month').format('DD-MM-YYYY'), dateRange.endDate || moment().format('DD-MM-YYYY'))
      .then((res) => {
        setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, false);
        setAssignmentHistory(res.data.data);
        saveAssignmentHistory(res.data.data);
      })
      .catch(({ response }) => {
        setLoadingDataState(LoadersState.ASSIGNMENT_HISTORY, false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const getAssignedTrainees = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, true);
    AnalyticsService.getAnalyticsOrganizationAssignedTrainees(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        let status = {
          completed: [] as any,
          inProgress: [] as any,
          notStarted: [] as any,
        };
        let completionRateNumerator = 0;
        let completionRateDenominator = 0;
        if (records) {
          records.map((record) => {
            completionRateNumerator += record.progress.completed.length;
            completionRateDenominator += record.progress.completed.length + record.progress.inProgress.length + record.progress.notStarted.length;
            if (record.progress.inProgress.length > 0 || (record.progress.completed.length === 0 && record.progress.notStarted.length === 0) || (record.progress.completed.length > 0 && record.progress.notStarted.length > 0)) {
              status.inProgress.push(record);
            } else {
              if (record.progress.completed.length > 0) {
                status.completed.push(record);
              } else {
                status.notStarted.push(record);
              }
            }
          });
          if (completionRateDenominator <= 0 || completionRateNumerator <= 0) {
            setCompletionRate('0%');
          } else setCompletionRate(((completionRateNumerator / completionRateDenominator) * 100).toFixed(2) + '%');
          setAssignedTraineesData(status);
          saveAssignedTraineesList(status);
        }
        setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.ASSIGNED_TRAINEES, false);
      });
  };

  const getSummary = (dateRange: IDateRange) => {
    setLoadingDataState(LoadersState.TRAINER_ORG_OVERVIEW, true);
    AnalyticsService.getAnalyticsOrganizationNewlyAdded(dateRange.startDate, dateRange.endDate)
      .then((e) => {
        const {
          data: { data: records },
        } = e;
        setWiOverview([
          {
            name: 'New',
            value: records.newlyAddedWorkInstructions,
            color: '#0B79BF',
          },
          {
            name: 'Total',
            value: records.totalWorkInstructions,
            //color: '#CC668C',
            color: '#95d1f7',
          },
        ]);
        setProcedureOverview([
          {
            name: 'New',
            value: records.newlyAddedProcedures,
            //color: '#4F7942',
            color: '#9D6647',
          },
          {
            name: 'Total',
            value: records.totalProcedures,
            color: '#DEBFAD',
          },
        ]);

        setMyOverview(records);

        setLoadingDataState(LoadersState.TRAINER_ORG_OVERVIEW, false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoadingDataState(LoadersState.TRAINER_ORG_OVERVIEW, false);
      });
  };

  const handleDateRangeChange = (dataRange: IDateRange) => {
    setSelectedFilter(dataRange);
  };

  const { t } = useTranslation();

  return (
    <Box component="div" className="analytics" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box component="div" sx={{ marginBottom: '20px' }}>
        <Header onDateRangeChange={handleDateRangeChange} overview={overview} setOverview={setOverview} />
      </Box>
      {overview === 'trainee' && <TraineeOverview loadingData={loadingData} LoadersState={LoadersState} currentDateRange={currentDateRange} currentFilterType={currentFilterType} pendingProcedures={pendingProcedures} startDate={startDate} endDate={endDate} assignedTraineesData={assignedTraineesData} completionRate={completionRate} assignmentHistory={assignmentHistory} filter={filterType} />}
      {overview === 'trainer' && <TrainerOverview loadingData={loadingData} LoadersState={LoadersState} wiOverview={wiOverview} procedureOverview={procedureOverview} procedurePublishOverview={procedurePublishOverview} trainerUserOverview={trainerUserOverview} myOverview={myOverview} currentDateRange={currentDateRange} currentFilterType={currentFilterType} pendingProcedures={pendingProcedures} />}
      {overview === 'training' && <TrainingOverview />}
    </Box>
  );
};

export default AnalyticsContainer;
