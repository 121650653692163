import { Button, FormControl, InputAdornment, MenuItem, Select, useTheme } from '@mui/material';
import React from 'react';
import { ActionFilter } from '../Icons/CdsIcons';
import { FilterDropdown, FilterOption } from '../Dialog/mobileDialog/filterDialog/filterDialog';
import { useTranslation } from 'react-i18next';
import '../../pages/Trainee/TraineeHome/TraineeHome.scss'

interface FilterButtonProps {
    selectedFilters: string,
    onApplyFilterClick: (any) => void,
    onFilterClose: () => void,
    filterOptions: FilterOption[],
    onOpen?: () => void
}

const FilterButton = (props: FilterButtonProps) => {
    const theme = useTheme();

    const { selectedFilters, onApplyFilterClick, onFilterClose, filterOptions } = props;
    const {t} = useTranslation();
    const [filterDropdownOpen, setFilterDropdownOpen] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<String>('');

    const onFilterChange = (e) => {
      setFilter(e?.target?.value);
    };

    const handleApplyFilter = (filterOptions: FilterOption[]) => {
      onApplyFilterClick(filterOptions);
    }

    const handleFilterOpen = () => {
      props.onOpen && props.onOpen();
      setFilterDropdownOpen(!filterDropdownOpen);
    }
  return (
    <FormControl sx={{ minWidth: 120, display: { xs: 'none', md: 'flex' } }}>
      <Button
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          padding: 0
        }}
        onClick={handleFilterOpen}
        disableRipple
      >
        <Select
          open={filterDropdownOpen}
          startAdornment={
            <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
              {' '}
              <ActionFilter />
            </InputAdornment>
          }
          sx={{ color: theme.palette.primary.main }}
          value={filter}
          onChange={onFilterChange}
          size="small"
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" sx={{ display: 'none', color: theme.palette.primary.main }}>
            {' '}
            <em>{t('COMMON.FILTER')}</em>{' '}
          </MenuItem>
          <FilterDropdown 
            activeFilters={selectedFilters} 
            open={filterDropdownOpen} 
            filter={filter} 
            setFilter={setFilter} 
            filterOptions={filterOptions} 
            onClose={onFilterClose} 
            applyFilters={handleApplyFilter}
        />
        </Select>
      </Button>
    </FormControl>
  );
};

export default FilterButton;
