import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { PieChart, Pie, Label, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { lighten } from '../../Cards/TourCard/TourCard';

const OrganizationOverview = ({ chartData, title }) => {
  interface CustomTooltipProps {
    active?: any;
    payload?: any;
    label?: any;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}: `}
            {`${payload[0]?.payload?.value}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ResponsiveContainer width="100%">
            <PieChart>
              <defs>
                {chartData?.map((entry, index) => {
                  return (
                    <linearGradient key={index} id={`myGradient${entry.color}`}>
                      <stop offset="0%" stopColor={entry.color} />
                      <stop offset="60%" stopColor={lighten(entry.color, 0.4)} />
                      <stop offset="100%" stopColor={lighten(entry.color, 0.5)} />
                    </linearGradient>
                  );
                })}
              </defs>

              <Pie isAnimationActive={true} data={chartData} strokeWidth={'1px'} innerRadius={65} outerRadius={85} stroke="white" dataKey="value">
                {chartData?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#myGradient${entry.color})`} />
                ))}
                <Label fontSize="28px" fill="#000000" value={chartData ?chartData[0]?.value : ''} position="center" />
              </Pie>
              <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex' }}>
          <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '16px', display: 'flex', flexDirection: 'flex-start', mb: '24px' }}>{title}</Typography>
            {chartData?.map((item, index) => (
              <Box key={index} component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '16px' }}>
                <CircleIcon sx={{ color: item.color, marginRight: '8px', fontSize: '16px', minWidth: 0 }} />
                <Box component="span" sx={{ color: '#000', fontSize: '16px' }}>
                  {item.name} {':'} {item.value}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationOverview;
