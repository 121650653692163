import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, MenuItem, Menu, useTheme, styled, Theme, Divider, Badge, Paper, Drawer, DialogTitle } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { CommentSideBar } from '../../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import { CommentSliderWindow } from '../../Analytics/ProcedureReports/CommentsSideBar/CommentSliderWindow';
import TutorialService from '../../../services/trainee/TraineeService';
import { Toast } from '../../Notifications/Notification';
import { Access } from '../../Auth/Access';
import { UserPermissions, WorkInstructionPermissions } from '../../../shared/permissions';
import Loading from '../../Loading/Loading';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import { CloseIcon } from '../../Icons/CdsIcons';

const formatDate = (timeStamp: number) => {
  const date = new Date(timeStamp);
  const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate.toString();
};

interface NotificationCardProps {
  notification: any;
}

interface AppBarNotificationProps {
  isTrainer?: boolean;
  companyId?: string;
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

const RevisitNotification: React.FC<NotificationCardProps> = ({ notification }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    notification.count > 0 && (
      <LinkRouter to="/trainer/RevisitRequests/">
        <MenuItem aria-controls="menu-user-manage-md" aria-haspopup="true" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black', width: '100%' }}>
            <Typography sx={{ fontSize: '14px' }}>
              <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.PERIODIC_REVIEWS') : t('NAVBAR.PERIODIC_REVIEW')}</span>
            </Typography>
          </Box>
        </MenuItem>
      </LinkRouter>
    )
  );
};

const StepCommentNotification: React.FC<any> = (props) => {
  const { notification, isTrainer } = props;
  const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
  const [showChats, setShowChats] = React.useState<boolean>(false);
  const traineeSpecificCommentHandler = () => {
    setShowChats(true);
  };
  const [activeThreadId, setActiveThreadId] = useState<string>('');

  const { t } = useTranslation();
  return (
    <>
      {showChats && (
        <>
          <CommentSideBar showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} isTrainer={isTrainer} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} notification={notification} />
          <CommentSliderWindow showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} showChats={showChats} setShowChats={setShowChats} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} notification={notification} isTrainer={isTrainer} />
        </>
      )}
      <MenuItem onClick={() => traineeSpecificCommentHandler()} aria-controls="menu-user-manage-md" aria-haspopup="true" sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Box component="span" sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px' }}>
          <Typography sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>
            <span>{notification.count}</span>{' '}
            <span style={{ fontWeight: '400' }}>
              {' '}
              {notification?.count > 1 ? t('ANALYTICS.COMMENTS').toLowerCase() : t('ANALYTICS.COMMENT').toLowerCase()} {t('PROCEDURE.ON')}{' '}
            </span>{' '}
            {notification?.target?.procedureName}
          </Typography>
          <Typography sx={{ color: '#12181F66', fontSize: '12px', fontWeight: '400' }}>{getFormattedDateFromUnixTime(notification?.timeStamp / 1000)}</Typography>
        </Box>
      </MenuItem>
    </>
  );
};

const RecertificationNotification: React.FC<any> = (props) => {
  const { notification, companyId } = props;
  const { t } = useTranslation();
  return (
    <Access permission={WorkInstructionPermissions.READ}>
      <LinkRouter to={`/recertificationDueRequest/${companyId}`}>
        <MenuItem aria-controls="menu-user-manage-md" aria-haspopup="true" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box component="span" sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black' }}>
            <Typography sx={{ fontSize: '14px' }}>
              <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.RECERTIFICATIONS_DUE') : t('NAVBAR.RECERTIFICATION_DUE')}</span>
            </Typography>
          </Box>
        </MenuItem>
      </LinkRouter>
    </Access>
  );
};

const ApprovalNotification: React.FC<NotificationCardProps> = (props) => {
  const { notification } = props;
  const { t } = useTranslation();
  return (
    <LinkRouter to="/trainer/ApprovalRequests/">
      <MenuItem aria-controls="menu-user-manage-md" aria-haspopup="true" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Box component="span" sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black' }}>
          <Typography sx={{ fontSize: '14px' }}>
            <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.APPROVAL_REQUESTS') : t('NAVBAR.APPROVAL_REQUEST')}</span>
          </Typography>
        </Box>
      </MenuItem>
    </LinkRouter>
  );
};

const getNotificationByType = (notification, isTrainer, companyId) => {
  switch (notification.action) {
    case 'revisit':
      return <RevisitNotification notification={notification} />;
    case 'step comment':
      return <StepCommentNotification notification={notification} isTrainer={isTrainer} />;
    case 'recertification':
      return notification.count > 0 && <RecertificationNotification notification={notification} companyId={companyId} />;
    case 'approval':
      return notification.count > 0 && <ApprovalNotification notification={notification} />;
    default:
      return false;
  }
};

const AppBarNotification: React.FC<AppBarNotificationProps> = (props) => {
  const { isTrainer, companyId } = props;

  const location = useLocation();
  const trainerHomePath = new RegExp(`trainer/home`);
  const traineeHomePath = new RegExp('home');
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [anchorElNotificationMenu, setAnchorElNotificationMenu] = React.useState<null | HTMLElement>(null);
  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);

  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotificationMenu(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotificationMenu(null);
  };
  const listStepCommentThread = () => {
    setNotificationLoading(true);
    TutorialService.notificationList()
      .then((e) => {
        setNotifications(e.data.data);
        setNotificationLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
        setNotificationLoading(false);
      });
  };
  useEffect(() => {
    listStepCommentThread();
  }, []);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      {notifications.length ? (
        <>
          <MenuItem aria-controls="menu-admin" aria-haspopup="true" onClick={handleOpenNotificationMenu}>
            <Badge badgeContent={notifications.length} color="primary" sx={{ margin: '0px 16px 0px 16px', borderRadius: '8px' }}>
              <NotificationsActiveIcon />
            </Badge>
          </MenuItem>
          <Drawer
            id="menu-admin-md"
            keepMounted
            onClick={handleCloseNotificationMenu}
            open={Boolean(anchorElNotificationMenu)}
            onClose={handleCloseNotificationMenu}
        
            aria-controls={anchorElNotificationMenu ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorElNotificationMenu ? 'true' : undefined}
            anchor={'right'}
            hideBackdrop
            PaperProps={{
              sx: { width: '432px', padding: '24px', backgroundColor: { xs: 'none' } },
            }}
          >
            {notificationLoading && <Loading />}
            <DialogTitle sx={{ fontSize: '16px', padding: '2px 12px 2px 12px', marginBottom: '22px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', top: '14px', left: '14px' }}>
                <Typography sx={{width: '245px', fontWeight: '600', fontSize: '18px', lineHeight: '21.6px'}}>{t('NAVBAR.NOTIFICATION') as string}</Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseNotificationMenu}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    padding: 0
                  }}
                >
                  <CloseIcon  />
                </IconButton>
              </Box>
            </DialogTitle>

            {notifications?.map((notification, index) => (
              <Box component={'div'} key={index} sx={{ backgroundColor: notification.action !== 'step comment' ? theme.palette.primary.main + '1c' : null, marginBottom: '8px', borderRadius: notification.action !== 'step comment' ? '8px' : null }}>
                {getNotificationByType(notification, isTrainer, companyId)}
              </Box>
            ))}
          </Drawer>
        </>
      ) : (
        <NotificationsNoneIcon sx={{ margin: '0px 16px 0px 16px' }} />
      )}
    </>
  );
};

export default AppBarNotification;
