export const getAppTheme = (themeColor: string) => {
  // Global MUI theme overrides are here
  const muiTheme: any = {
    typography: {
      fontFamily: 'Fira Sans',
      button: {
        textTransform: 'none',
      },
      allVariants:{
        fontWeight: "600"
      }
    },
    palette: {
      mode: 'light',
      primary: {
        main: themeColor ?? '#0061a1',
      },
    },
    spacing: 8,
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        defaultProps: {
          margin: 'dense',
        },
        styleOverrides: {
          root: {
            lineHeight: 2,
            minWidth: 100,
            boxShadow: 'none',
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
    },
  };

  return muiTheme;
};
