import { Avatar, Box, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import '../../TraineeHome.scss';
import { SUPPORTED_VIEWS } from '../../../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import * as HomePageAction from '../../../../../redux/features/HomePage/HomePage.slice';
interface VIEW_SELECTOR_OPTION {
  viewName: SUPPORTED_VIEWS;
  label: string;
  count: number;
}

interface ViewSelectorProps {
  options: VIEW_SELECTOR_OPTION[];
}

const ViewSelector: React.FC<ViewSelectorProps> = (props) => {
  const { options } = props;
  const {currentView} = useAppSelector(state => state.homePage)
  const dispatch = useAppDispatch();

  const setCurrentView = (view: SUPPORTED_VIEWS) => {
    dispatch(HomePageAction.setCurrentView(view))
  }

  const handleViewSelect = (view: SUPPORTED_VIEWS) => {
    setCurrentView(view); // Update current view state
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
      }}
    >
      {options.map((option, index) => (
        <Box
          key={index}
          component="div"
          onClick={() => handleViewSelect(option.viewName)}
          sx={{
            display: 'flex',
            gap: '10px',
            borderColor: 'primary.main',
            borderBottom: currentView === option.viewName ? '2px solid' : 'none',
            color: 'primary.main',
            opacity: currentView === option.viewName ? 1 : 0.5,
            cursor: 'pointer',
            padding: '10px 0',
          }}
        >
          <Typography variant="h6" component="div" className="page-title">
            {option.label}
          </Typography>
          <Avatar
            sx={{
              bgcolor: 'grey.300',
              color: 'primary.main',
              height: '32px',
              width: 'auto',
              padding: '0 5px',
              fontWeight: 600,
            }}
            variant="rounded"
          >
            {option.count}
          </Avatar>
        </Box>
      ))}
    </Box>
  );
};

export default ViewSelector;
