import { Backdrop, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';
import { closeSnackbar } from 'notistack';
import { Toast } from '../../../Notifications/Notification';
import { WarningIcon } from '../../../Icons/CdsIcons';

export const DuplicateFolderDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [duplicatedItems, setDuplicateItems] = useState<any>(0);
  const { t } = useTranslation();
  const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
  const handleDuplicateFolder = () => {
    setLoading(true);
    let duplicatePayload: any = [];
    props?.selectedFolderWiCard?.forEach((value) => {
      const parsedValue = JSON.parse(value);
      duplicatePayload.push(parsedValue);
    });
    const duplicateFolderPayload = {
      entitiesToCopy: duplicatePayload,
      newParentFolderId: props.parentFolderId,
    };

    TrainerService.duplicateFolder(duplicateFolderPayload)
      .then((e) => {
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), duplicatePayload.length > 1 ? t('COMMON.ITEMS_DUPLICATED_SUCCESSFULLY', { itemSize: duplicatePayload.length }) : t('COMMON.ITEM_DUPLICATED_SUCCESSFULLY'));
        props.handleReloadData();
        setLoading(false);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        setLoading(false);
      })
      .finally(() => {
        props.clearCardsSelection();
        setLoading(false);
      });
    props.closeDialog();
  };
  useEffect(() => {
    if (props?.openDialog) {
      setDuplicateItems(props?.selectedFolderWiCard?.size);
    }
  }, [props?.selectedFolderWiCard]);

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      <BasicDialog
        title={duplicatedItems > 1 ? t('COMMON.DUPLICATE_ITEMS') : t('COMMON.DUPLICATE_ITEM')}
        onClose={() => {
          props.closeDialog();
        }}
        onOpen={props.openDialog}
        actions={
          <>
            <Button
              onClick={() => {
                props.closeDialog();
              }}
              variant="outlined"
              size="small"
              type="submit"
            >
              {t('BUTTON.CANCEL') as string}
            </Button>
            <Button
              onClick={() => {
                handleDuplicateFolder();
              }}
              variant="contained"
              size="small"
              type="submit"
            >
              {t('COMMON.DUPLICATE') as string}
            </Button>
          </>
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft:'16px' }}>
          <Typography sx={{ fontSize: '16px', display: 'flex', flexDirection: 'row', fontWeight: '400', color:'darkred', gap:'10px' }}>
            <WarningIcon />
            {" " + t('COMMON.DUPLICATE_FOLDER_WARNING')}  
          </Typography>
          <Typography sx={{ paddingLeft: '34px', fontWeight: '400' }}>{duplicatedItems > 1 ? t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DUPLICATE_THESE_ITEM', { selectedItems: duplicatedItems }) : t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DUPLICATE_THIS_ITEM')} {t("COMMON.WITHOUT_3D_WORK_INSTRUCTIONS")}</Typography>
        </Box>
      </BasicDialog>
    </>
  );
};
