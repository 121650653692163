import React, { useEffect, useState, useRef } from 'react';
import { mediaType } from '../../../../entities/Trainee/WorkInstructions';
import { Box, Zoom, Tooltip, IconButton, Fade, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
interface Props {
  aReference: mediaType;
  index: number;
  onDelete?: (index: number) => void;
  commentMediaRef?: boolean;
}
const MediaRef: React.FC<Props> = ({ aReference, index, onDelete, commentMediaRef }) => {
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

  function handleTileClick() {
    setShowVideoModal(true);
  }
  function closeModal(e) {
    e.stopPropagation();
    setShowVideoModal(false);
  }

  function openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const { t } = useTranslation();

  return (
    <>
      <Box component={'div'} onClick={handleTileClick} sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 100px', borderRadius: '10px', border: '4px solid #bfbfbf', height: '100px', width: '100px', margin: '1.5%' }}>
        <Tooltip placement="bottom" title={aReference.title}>
          <Box component={'div'} sx={{ width: '100%', height: 'calc( 100% - 16px )', display: 'flex', position: 'relative' }}>
            {(aReference.dataUrl || aReference.url) && (
              <>
                {!commentMediaRef && (
                  <Box component={'div'} sx={{ position: 'absolute', left: '0px', top: '0px', zIndex: 10 }}>
                    <Tooltip title={t('COMMON.DELETE')}>
                      <IconButton onClick={() => onDelete && onDelete(index)} sx={{ backgroundColor: '#d4d4d4ad', ':hover': { backgroundColor: '#f7f7f7d9' } }}>
                        <DeleteOutlineIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                {aReference.fileType.includes('image') && aReference.dataUrl && !aReference.objectUrl && <img src={aReference.dataUrl} style={{ width: '100%', height: '100%' }}></img>}
                {aReference.fileType.includes('image') && aReference.objectUrl && <img src={aReference.objectUrl} style={{ width: '100%', height: '100%' }}></img>}

                {aReference.fileType.includes('video') && aReference.dataUrl && !aReference.objectUrl && (
                  <video id={'thisVideo'} style={{ width: '100%', height: '100%' }}>
                    <source src={aReference.dataUrl} type="video/mp4"></source>
                  </video>
                )}
                {aReference.fileType.includes('video') && aReference.objectUrl && (
                  <video id={'thisVideo'} style={{ width: '100%', height: '100%' }}>
                    <source src={aReference.objectUrl} type="video/mp4"></source>
                  </video>
                )}

                {aReference.fileType.includes('pdf') && aReference.dataUrl && !aReference.objectUrl && <embed src={aReference.dataUrl} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></embed>}
                {aReference.fileType.includes('pdf') && aReference.objectUrl && <embed src={aReference.objectUrl} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></embed>}

                {aReference.fileType.includes('image') && aReference.url && <img src={aReference.url} style={{ width: '100%', height: '100%' }} onClick={() => openInNewTab(aReference.url)}></img>}
                {aReference.fileType.includes('video') && aReference.url && (
                  <video id={'thisVideo'} onClick={() => openInNewTab(aReference.url)} style={{ width: '100%', height: '100%' }}>
                    <source src={aReference.url} type="video/mp4"></source>
                  </video>
                )}

                {aReference.fileType.includes('pdf') && aReference.url && <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '0', backgroundColor: 'transparent' }} onClick={() => openInNewTab(aReference.url)}></div>}
                {aReference.fileType.includes('pdf') && aReference.url && <embed src={aReference.url} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></embed>}

                {aReference.fileType.includes('youtubeUrl') && aReference.url && <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '0', backgroundColor: 'transparent' }} onClick={() => openInNewTab(aReference.url)}></div>}
                {aReference.fileType.includes('youtubeUrl') && aReference.url && <iframe src={'https://www.youtube.com/embed/' + aReference.title} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></iframe>}

                {aReference.fileType.includes('vimeoUrl') && aReference.url && <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '0', backgroundColor: 'transparent' }} onClick={() => openInNewTab(aReference.url)}></div>}
                {aReference.fileType.includes('vimeoUrl') && aReference.url && <iframe src={'https://player.vimeo.com/video/' + aReference.title} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></iframe>}

                {!aReference.fileType.includes('image') && !aReference.fileType.includes('video') && !aReference.fileType.includes('pdf') && !aReference.fileType.includes('youtubeUrl') && !aReference.fileType.includes('vimeoUrl') && aReference.url && <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '0', backgroundColor: 'transparent' }} onClick={() => openInNewTab(aReference.url)}></div>}
                {!aReference.fileType.includes('image') && !aReference.fileType.includes('video') && !aReference.fileType.includes('pdf') && !aReference.fileType.includes('youtubeUrl') && !aReference.fileType.includes('vimeoUrl') && aReference.url && <iframe src={aReference.url} style={{ width: '100%', height: '100%', pointerEvents: 'none' }}></iframe>}
              </>
            )}
            {aReference.isLoading && (
              <Box component={'div'} sx={{ width: '100%', height: '100%', backgroundColor: '#a1a1a17d', display: 'flex', position: 'absolute', left: '0px', top: '0px', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Tooltip>
        {aReference.dataUrl && !aReference.objectUrl && (
          <Modal
            onClose={closeModal}
            closeAfterTransition
            open={showVideoModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box onClick={(e) => closeModal(e)} component={'div'} sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 'left', left: '0px', background: '#ffffff00', width: '100%', height: '100%' }}>
              <Box component={'div'} sx={{ position: 'absolute' }}>
                {aReference.fileType.includes('video') && aReference.dataUrl && (
                  <video controls id={'thisVideo'} style={{ width: '50vw' }}>
                    <source src={aReference.dataUrl} type={'video/mp4'}></source>
                  </video>
                )}
                {aReference.fileType.includes('image') && aReference.dataUrl && <img src={aReference.dataUrl} style={{ width: '50vw' }}></img>}
                {aReference.fileType.includes('pdf') && aReference.dataUrl && <embed src={aReference.dataUrl} style={{ width: '80vw', height: '80vh' }}></embed>}
              </Box>
            </Box>
          </Modal>
        )}
        {aReference.objectUrl && (
          <Modal
            onClose={closeModal}
            closeAfterTransition
            open={showVideoModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box onClick={(e) => closeModal(e)} component={'div'} sx={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 'left', left: '0px', background: '#ffffff00', width: '100%', height: '100%' }}>
              <Box component={'div'} sx={{ position: 'absolute' }}>
                {aReference.fileType.includes('video') && aReference.objectUrl && (
                  <video controls id={'thisVideo'} style={{ width: '50vw' }}>
                    <source src={aReference.objectUrl} type={'video/mp4'}></source>
                  </video>
                )}
                {aReference.fileType.includes('image') && aReference.objectUrl && <img src={aReference.objectUrl} style={{ width: '50vw' }}></img>}
                {aReference.fileType.includes('pdf') && aReference.objectUrl && <embed src={aReference.objectUrl} style={{ width: '80vw', height: '80vh' }}></embed>}
              </Box>
            </Box>
          </Modal>
        )}
        <Typography sx={{ position: 'relative', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', height: '16px', fontSize: '0.7rem' }}>{aReference.title}</Typography>
      </Box>
    </>
  );
};

export default MediaRef;
