import { Refresh, Search } from '@mui/icons-material';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Avatar, Box, Button, Card, FormControl, Grid, IconButton, InputAdornment, Paper, styled, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { trackEvent, useDocumentTitle } from '../../../App';
import TableContainer from '../../../components/Analytics/ProcedureReports/TableContainer';
import CdsBreadCrumb, { getBreadCrumbRoute } from '../../../components/BreadCrumbs/CdsBreadCrumb';
import FolderCards from '../../../components/Cards/FolderCards/FolderCards';
import TourCard from '../../../components/Cards/TourCard/TourCard';
import WorkInstructionCards from '../../../components/Cards/WorkInstructionCard/WorkInstructionCards';
import { FilterDialog, FilterOption } from '../../../components/Dialog/mobileDialog/filterDialog/filterDialog';
import { SortDialog } from '../../../components/Dialog/mobileDialog/sortDialog/sortDialog';
import FilterButton from '../../../components/FilterButton/FilterButton';
import { ActionFilter, CloseCardIcon, DuplicateIcon, EmptyFolderIcon, MoveIcon, RenameIcon, SortDesc } from '../../../components/Icons/CdsIcons';
import Loading from '../../../components/Loading/Loading';
import { Toast } from '../../../components/Notifications/Notification';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SelectSort from '../../../components/SelectSort/SelectSort';
import { DeleteFolderDialog } from '../../../components/Trainer/Dialog/DeleteFolderDialog/DeleteFolderDialog';
import { DuplicateFolderDialog } from '../../../components/Trainer/Dialog/DuplicateFolderDialog/DuplicateFolderDialog';
import MoveFolderDialog from '../../../components/Trainer/Dialog/MoveFolderDialog/MoveFolderDialog';
import { IWorkInstructions } from '../../../entities/Trainee/MockTrainee';
import useTour from '../../../hooks/tour';
import * as HomePageAction from '../../../redux/features/HomePage/HomePage.slice';
import TrainerService from '../../../services/trainer/TrainerService';
import { AmplitudeEvents, SUPPORTED_VIEWS, translate, TRANSLATE_TRAINEE_FILTERS, WorkInstructionModelStatus } from '../../../shared/enums';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import './TraineeHome.scss';
import { HOMEPAGE_DIALOG } from './constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTemplateProcedures, setSelectedFiltersAction } from '../../../redux/features/ModularProcedures/ModularProcedures.slice';
import ProcedureLibraryToolbar from '../../Trainer/ProcedureLibrary/ProcedureLibraryToolbar';
import { EntityName } from './ReuseableComponents';
import ViewSelector from './Toolbar/ViewSelector/ViewSelector';
import './TraineeHome.scss';
import useLoadEntities from '../../../hooks/useLoadEntities';
import ProcedureLibrary from '../../Trainer/ProcedureLibrary/ProcedureLibrary';
import AppliedFilterList from '../../../components/FilterButton/AppliedFilterList';
import useLoadFolders from '../../../hooks/useLoadFolders';
interface Props {
  isTrainer?: boolean;
  workInstructionAppBar?: boolean;
  setWorkInstructionAppBar?: any;
}

const TraineeHome: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentView, folders, workInstructions, dialogToShow } = useAppSelector((state) => state.homePage);
  const {
    templateProcedures: templateProceduresState,
    procedureLibrary: {
      toolbar: { selectedFilters: procedureLibraryAppliedFilterList },
    },
  } = useAppSelector((state) => state.modularProcedure);
  const {
    loading: isTemplateProcedureLoading,
    data: { data: templateProcedures, totalDocuments: totalTemplateProcedures },
  } = templateProceduresState;

  const { companyId } = useParams();
  useDocumentTitle('CDS Mentor Home');
  const { createWiTour, setCreateWiTour } = useTour();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loadData, totalWorkInstructionsLength, currPage, parentFolderId, loading: entitiesLoading, setCurrPage, hasNext, imageObject, setParentFolderId, totalFolderWorkInstructionLength } = useLoadEntities();

  const { moveParentFolderId } = useLoadFolders();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [sort, setSort] = useState<string>('');
  const [sortVal, setSortVal] = useState<string>('');
  const [filterVal, setFilterVal] = useState<string>('');
  const [searchVal, setSearchVal] = useState<string>('');
  const [appliedFilterList, setAppliedFilterList] = useState<Array<any>>([]);
  const [isCreateFolder, setIsCreateFolder] = useState<boolean>(false);
  const [selectedFolderWiCard, setSelectedFolderWiCard] = useState(new Set());
  const [breadCrumbRoute, setBreadCrumbRoute] = useState<any>([]);
  const [isRenameNavBar, setIsRenameNavBar] = useState<boolean>(false);
  const [overview, setOverview] = useState<string>('normal_view');
  const [workInstructionTableData, setWorkInstructionTableData] = React.useState<any>([]);
  const [checkedMoveToFolderId, setCheckedMoveToFolderId] = React.useState<string>('');
  const [isRenameFolderId, setIsRenameFolderId] = React.useState<string>('');
  const [is2dMap, setIs2dMap] = useState<Map<string, boolean>>(new Map());
  const [newFolderName, setNewFolderName] = React.useState('');
  const [hideDuplicateButton, setHideDuplicateButton] = React.useState<boolean>(false);

  const [filterOptionsTrainee, setFilterOptionsTrainee] = useState<FilterOption[]>([
    {
      filter: 'completionStatus',
      filterValues: ['Not started', 'In-Progress', 'Completed', 'Expired'],
    },
    // {
    //   filter: 'recertification_Due',
    //   filterValues: ['Expired'],
    // },

    // {
    //   filter: 'Brand',
    //   filterValues: ['Heil', 'Marathon', 'Bayne', 'Soft pack']
    // },
    // {
    //   filter: 'Variants',
    //   filterValues: ['single', 'multiple'],
    // }
  ]);
  const [filterOptionsTrainer, setFilterOptionsTrainer] = useState<FilterOption[]>([
    {
      filter: 'status',
      filterValues: [WorkInstructionModelStatus.FILE_UPLOAD_FAILED, WorkInstructionModelStatus.MODEL_CONVERSION_FAILED, WorkInstructionModelStatus.PREPARING_MODEL, WorkInstructionModelStatus.MODEL_READY],
    },
  ]);

  useEffect(() => {
    if (companyId) {
      loadData(parentFolderId);
      if (props.isTrainer) dispatch(fetchTemplateProcedures(null));
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (overview === 'table_view') {
      getworkInstructionTableData();
    }
  }, [overview, currPage, workInstructions]);

  useEffect(() => {
    // once the view changes to procedure library -> remove the breadcrumbs
    // currently reseting the view itself
    setBreadCrumbRoute([]);
    setCurrPage(1);
    loadData('');
  }, [currentView]);

  let clickTimeout: any = null;

  const getParentFolderIdOfEntity = (entityId, entityType) => {
    let data;
    if (entityType === 'WorkInstruction') {
      data = workInstructions.filter((wi) => wi.workInstructionId === entityId);
    } else {
      data = folders.filter((folder) => folder.folderId === entityId);
    }
    return data[0].parentFolderId || '';
  };
  const handleCardClick = (entityId, entityType, is2d?: boolean) => {
    if (!isRenameNavBar) {
      const newFolderWiCards = new Set(selectedFolderWiCard);
      const entityParentFolderId = getParentFolderIdOfEntity(entityId, entityType);
      const cardObject = JSON.stringify({ id: entityId, type: entityType, parentFolderId: entityParentFolderId });

      const newIs2dMap = new Map(is2dMap);
      if (newFolderWiCards?.has(cardObject)) {
        newFolderWiCards.delete(cardObject);
        newIs2dMap.delete(cardObject);
      } else {
        newFolderWiCards.add(cardObject);
        if (is2d !== undefined) {
          newIs2dMap.set(cardObject, is2d);
        }
      }
      setSelectedFolderWiCard(newFolderWiCards);
      setIs2dMap(newIs2dMap);
      const allWorkInstructions = Array.from(newFolderWiCards).every((card: any) => {
        const cardData = JSON.parse(card);
        return cardData.type === 'WorkInstruction';
      });
      const allNon2D = allWorkInstructions && Array.from(newIs2dMap.values()).every((value) => value === false);

      setHideDuplicateButton(allNon2D && allWorkInstructions && newFolderWiCards.size > 0);
    }
  };

  const setFolders = (data) => {
    dispatch(HomePageAction.setFolders(data));
  };

  const handleClickFolderWi = (cardId, isWorkinstruction, isFolderIconClicked?: boolean, is2d?: boolean) => {
    const type = isWorkinstruction ? 'WorkInstruction' : 'Folder';
    if (clickTimeout !== null || isFolderIconClicked) {
      setCurrPage(1);
      clearCardsSelection();
      setIsCreateFolder(false);
      clearTimeout(clickTimeout);
      clickTimeout = null;
      if (isWorkinstruction) {
        if (companyId) {
          props.isTrainer ? navigate(`/trainer/procedures/${companyId}/${cardId}`) : navigate(`/procedures/${companyId}/${cardId}`);
        }
      } else {
        const cardIdLocation = cardId ? cardId : '';
        getBreadCrumbRoute(cardIdLocation, type).then((arr) => {
          setBreadCrumbRoute(arr);
        });

        loadData(cardId);
        setSort('');
        setSortVal('');
        setFilterVal('');
        setSearchVal('');
      }
    } else {
      // handle single click on element
      clickTimeout = setTimeout(() => {
        if (props.isTrainer) {
          clearCardsSelection();
          handleCardClick(cardId, type, is2d);
        }
        clickTimeout = null;
      }, 500);
    }
  };

  const applyFilters = (fullFilterList) => {
    let filter = '';
    let prevFilter = '';
    setAppliedFilterList(fullFilterList);
    fullFilterList?.map((item) => {
      if (item.applied) {
        if (item.filter === prevFilter) {
          if (item.filter === 'completionStatus' && item.value === 'Expired') {
            item.filter = 'hasExpiredProcedures';
            item.value = true;
            filter += ',' + item.filter + ':' + item.value;
          } else filter += '.' + item.value;
        } else {
          console.log(item.filter, item.value);
          if (item.filter === 'completionStatus' && item.value === 'Expired') {
            item.filter = 'hasExpiredProcedures';
            item.value = true;
          }
          filter += ',' + item.filter + ':' + item.value;
        }
        prevFilter = item.filter;
      }
    });
    setFilterVal(filter);
    if (companyId) {
      loadData(parentFolderId, searchVal, sortVal, filter);
    }
  };

  const sortFilter = (sort: string) => {
    if (sort === 'Alphabetical') {
      setSortVal('name:asc');
      loadData(parentFolderId || '', searchVal, 'name:asc', filterVal);
    } else if (sort === 'Date Added') {
      setSortVal('modifiedOn:asc');
      loadData(parentFolderId || '', searchVal, 'modifiedOn:asc', filterVal);
    } else {
      setSortVal('modifiedOn:desc');
      loadData(parentFolderId || '', searchVal, 'modifiedOn:desc', filterVal);
    }
  };

  // const loadData = (folderId: String, search = searchVal, sort = sortVal, filter = filterVal, page = 1, pageLimit = 15) => {
  //   if (previousController.current) {
  //     previousController.current.abort();
  //   }
  //   const controller = new AbortController();
  //   const signal = controller.signal;
  //   previousController.current = controller;

  //   TrainerService.folderContent(folderId, page, pageLimit, search, sort, filter, signal)
  //     .then((e) => {
  //       setTotalWorkInstructionsLength(e?.data?.data?.total);
  //       e?.data?.data?.workinstructions?.map((wi) => {
  //         FileManagerService.getWorkInstructionThumbnail(localStorage.getItem('organizationId') || '', wi.workInstructionId)
  //           .then((res) => {
  //             imageObject[wi.workInstructionId] = (res as any).data[0].id;
  //             setImageObject({ ...imageObject });
  //           })
  //           .catch((e) => {
  //             imageObject[wi.workInstructionId] = '';
  //           });
  //       });

  //       if (workInstructions && folders && page > 1) {
  //         setWorkInstructions([...workInstructions, ...(e?.data?.data?.workinstructions as IWorkInstructions[])]);
  //         setFolders([...folders, ...(e?.data?.data?.folders as any)]);
  //       } else {
  //         setWorkInstructions(e?.data?.data?.workinstructions as IWorkInstructions[]);
  //         setFolders(e?.data?.data?.folders as any);
  //       }

  //       setCurrPage(page + 1);
  //       setParentFolderId(folderId);
  //     })
  //     .catch(({ response }) => {
  //       setLoading(false);
  //     });
  // };

  const handleReloadData = async () => {
    if (companyId) {
      await loadData(parentFolderId);
      getworkInstructionTableData();
    }
  };

  const handleCreateFolder = () => {
    setIsCreateFolder(true);
  };

  const StyledNavLink = styled(NavLink)({
    padding: '10px 24px',
    borderRadius: '40px',
    marginRight: '16px',
    whiteSpace: 'nowrap',
    fontSize: ' 0.875rem',
    '&.active': {
      border: '2px solid' + theme.palette.primary.main,
    },
  });

  const search = (key: string) => {
    setSearchVal(key);

    if (companyId) loadData(parentFolderId, key);
  };

  const onSortClose = () => {
    setOpen(false);
  };
  const onFilterClose = () => {
    setFilterDropdownOpen(false);
    setFilterOpen(false);
  };

  const sortByModifiedDate = (ws: IWorkInstructions[]) => {
    if (ws) {
      return ws.sort((obj1, obj2) => {
        if (obj1.modifiedOn < obj2.modifiedOn) {
          return 1;
        }
        if (obj1.modifiedOn > obj2.modifiedOn) {
          return -1;
        }
        return 0;
      });
    }
  };

  const removeFilter = (filter, value) => {
    let newArr = [...appliedFilterList];
    newArr?.map((item) => {
      if (item.filter === filter && item.value === value) {
        item.applied = false;
      }
    });
    applyFilters(newArr);
    setAppliedFilterList(newArr);
  };

  const handleRename = () => {
    setIsRenameFolderId(JSON.parse(selectedFolderWiCard?.values()?.next()?.value).id);
    setIsRenameNavBar(true);
  };

  const handleMove = () => {
    dispatch(HomePageAction.setDialogToShowAction(HOMEPAGE_DIALOG.MOVE_ENTITY));
  };
  const handleMoveFolder = (targetFolder) => {
    let movePayload: any = [];

    selectedFolderWiCard?.forEach((value: any) => {
      const entity = JSON.parse(value);
      if (entity.parentFolderId === targetFolder) return;
      delete entity.parentFolderId;

      movePayload.push(entity);
    });

    if (!movePayload.length) {
      clearCardsSelection();
      closeDialogHandler();
      return;
    }
    TrainerService.moveToFolder(targetFolder, movePayload)
      .then((e) => {
        Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e.data.messageCode, { ns: 'success' }) || e.data.message);
        handleReloadData();
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      })
      .finally(() => {
        clearCardsSelection();
        closeDialogHandler();
      });
  };

  const handleDuplicateDialog = () => {
    dispatch(HomePageAction.setDialogToShowAction(HOMEPAGE_DIALOG.DUPLICATE_ENTITY));
  };

  const handleDeleteDialog = () => {
    dispatch(HomePageAction.setDialogToShowAction(HOMEPAGE_DIALOG.DELETE_ENTITY));
  };

  const handleDuplicateFolder = () => {
    // const modifiedFolders = folders?.unshift(props.folder);
    // setFolders(modifiedFolders);
    closeDialogHandler();
  };

  const closeDialogHandler = () => {
    dispatch(HomePageAction.setDialogToShowAction(undefined));
  };
  const workInstructionColumn = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: EntityName,
        },
        {
          Header: 'Description',
          accessor: 'description',
          disableSortBy: true,
          Cell: ({ value, row }) => <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value}</Typography>,
        },
        {
          Header: 'Brand',
          accessor: 'brand',
          Cell: ({ value, row }) => <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value}</Typography>,
        },
        {
          Header: 'Date Added',
          accessor: 'addedOn',
          Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{getFormattedDateFromUnixTime(value)}</span>,
        },
      ].filter(Boolean),
    [overview, currPage, workInstructions],
  );

  const getworkInstructionTableData = () => {
    const arr: any = [];
    folders?.map((folder) => {
      arr.push({
        name: folder.title,
        description: folder.description,
        brand: '',
        addedOn: folder.modifiedOn ? folder.modifiedOn : folder.createdOn,
        id: folder.folderId,
        type: 'Folder',
        workInstructionImageURL: '',
        companyId: companyId,
        parentFolderId: folder.parentFolderId
      });
    });
    workInstructions?.map((workInstruction) => {
      arr.push({
        name: workInstruction.workInstructionName,
        description: workInstruction.workInstructionDescription,
        brand: workInstruction.brand,
        addedOn: workInstruction.createdOn,
        id: workInstruction.workInstructionId,
        type: 'WorkInstruction',
        workInstructionImageURL: imageObject ? imageObject[workInstruction.workInstructionId] : '',
        companyId: companyId,
        workInstructionId: workInstruction.workInstructionId,
        parentFolderId: workInstruction.parentFolderId
      });
    });

    setWorkInstructionTableData(arr);
  };

  useEffect(() => {
    if (overview === 'table_view') {
      getworkInstructionTableData();
    }
  }, [overview, currPage, workInstructions]);

  useEffect(() => {
    if (props.isTrainer) {
      TrainerService.getNewFolderName(parentFolderId as string)
        .then((response) => {
          setNewFolderName(response.data.data);
        })
        .catch(console.error);
    }
  }, [folders]);
  const clearCardsSelection = () => {
    let emptySelectedFolder: any = new Set();
    setSelectedFolderWiCard(emptySelectedFolder);
  };

  const folderWorkInstructionTableRowClick = (row) => {
    if (row.original.type === 'WorkInstruction') {
      handleClickFolderWi(row.original.id, true);
    } else if (row.original.type === 'Folder') {
      handleClickFolderWi(row.original.id, false);
    }
  };

  const handleRemoveFilterClick = (item) => {
    switch (currentView) {
      case SUPPORTED_VIEWS.WORK_INSTRUCTION:
        removeFilter(item.filter, item.value);
        break;
      case SUPPORTED_VIEWS.PROCEDURE_LIBRARY:
        const filterToSet = procedureLibraryAppliedFilterList.map((each) => {
          if (each.filter === item.filter && each.value === item.value) {
            let filter = structuredClone(each);
            filter.applied = false;
            return filter;
          }
          return each;
        });
        dispatch(setSelectedFiltersAction(filterToSet));
        break;
    }
  };

  const handleBreadCrumbClick = () => {
    clearCardsSelection();
    setSort('');
    setSortVal('');
    setFilterVal('');
    setSearchVal('');
    setAppliedFilterList([]);
  };

  return (
    <>
      {(loading || entitiesLoading) && <Loading />}
      <MoveFolderDialog openDialog={dialogToShow === HOMEPAGE_DIALOG.MOVE_ENTITY} closeDialog={closeDialogHandler} handleMoveFolder={handleMoveFolder} parentFolderId={parentFolderId} selectedFolders={selectedFolderWiCard} />
      <DeleteFolderDialog openDialog={dialogToShow === HOMEPAGE_DIALOG.DELETE_ENTITY} closeDialog={closeDialogHandler} selectedFolderWiCard={selectedFolderWiCard} clearCardsSelection={clearCardsSelection} handleReloadData={handleReloadData} isMultiple={true} />
      <DuplicateFolderDialog openDialog={dialogToShow === HOMEPAGE_DIALOG.DUPLICATE_ENTITY} closeDialog={closeDialogHandler} selectedFolderWiCard={selectedFolderWiCard} clearCardsSelection={clearCardsSelection} handleReloadData={handleReloadData} handleDuplicateFolder={handleDuplicateFolder} parentFolderId={parentFolderId} isMultiple={true} />
      {!loading && (
        <div>
          {/* -------------- breadcrumbs start --------- */}
          <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <CdsBreadCrumb routes={breadCrumbRoute} folderBreadCrumb={true} setBreadCrumbRoute={setBreadCrumbRoute} setCurrPage={setCurrPage} loadData={loadData} workInstructionAppBar={props.workInstructionAppBar} setWorkInstructionAppBar={props.setWorkInstructionAppBar} btnClickHandler={handleBreadCrumbClick} />
          </Paper>
          {/* -------------- breadcrumbs end --------- */}

          {/* -------------- toolbar start --------- */}

          <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ViewSelector
              options={[
                { viewName: SUPPORTED_VIEWS.WORK_INSTRUCTION, label: t('TOOLBAR.ALL_WORK_INSTRUCTIONS') as string, count: totalWorkInstructionsLength },
                { viewName: SUPPORTED_VIEWS.PROCEDURE_LIBRARY, label: t('TOOLBAR.PROCEDURE_LIBRARY') as string, count: totalTemplateProcedures },
              ].slice(0, props.isTrainer ? 2 : 1)}
            />

            {currentView === SUPPORTED_VIEWS.PROCEDURE_LIBRARY ? <ProcedureLibraryToolbar /> : null}

            {/* //todo: move this logic to component */}
            {currentView === SUPPORTED_VIEWS.WORK_INSTRUCTION ? (
              <>
                {' '}
                {selectedFolderWiCard?.size === 0 && (
                  <Box component="div" sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    gap: "5px"
                  }}>
                    <SearchBar onChangeHandler={(e) => search(e?.target?.value)} />
                    <SelectSort
                      sortOptions={[
                        { value: 'Date Added', label: 'WI_LIST.DATE_ADDED' },
                        { value: 'Alphabetical', label: 'WI_LIST.ALPHABETICAL' },
                      ]}
                      onChange={(value) => {
                        setSort(value);
                        sortFilter(value);
                      }}
                      value={sort}
                    />
                    <FilterButton selectedFilters={filterVal} onApplyFilterClick={applyFilters} onFilterClose={onFilterClose} filterOptions={props.isTrainer ? filterOptionsTrainer : filterOptionsTrainee} />
                    <Box component="div">
                      <Tabs
                        value={overview === 'normal_view' ? 0 : overview === 'extended_view' ? 1 : 2}
                        onChange={(e, newTabIndex) => {
                          setOverview(newTabIndex === 0 ? 'normal_view' : newTabIndex === 1 ? 'extended_view' : 'table_view');
                        }}
                        sx={{ '& .MuiTabs-indicator': { display: 'none' }, marginRight: '8px' }}
                      >
                        <Tab
                          sx={{ padding: 0, minWidth: 'unset' }}
                          label={
                            <Button size="small" variant={overview === 'normal_view' ? 'contained' : 'outlined'} sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '8px 0px 0px 8px' }}>
                              <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                              <span className="material-symbols-outlined">grid_view</span>
                            </Button>
                          }
                        />
                        <Tab
                          sx={{ padding: 0, minWidth: 'unset' }}
                          label={
                            <Button size="small" variant={overview === 'extended_view' ? 'contained' : 'outlined'} sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '0px 0px 0px 0px' }}>
                              <span className="material-symbols-outlined">apps</span>
                            </Button>
                          }
                        />
                        <Tab
                          sx={{ padding: 0, minWidth: 'unset' }}
                          label={
                            <Button size="small" variant={overview === 'table_view' ? 'contained' : 'outlined'} sx={{ margin: 0, minWidth: '32px', height: '40px', borderRadius: '0px 8px 8px 0px' }}>
                              <span className="material-symbols-outlined">list</span>
                            </Button>
                          }
                        />
                      </Tabs>
                    </Box>
                    <Tooltip title={t('WI_LIST.REFRESH_WORK_INSTRUCTIONS')}>
                      <IconButton color="primary" sx={{ mr: props.isTrainer ? 1 : 0, borderRadius: '8px' }} onClick={handleReloadData}>
                        <Refresh />
                      </IconButton>
                    </Tooltip>

                    {props.isTrainer && (
                      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box>
                          <Tooltip title={t('WI_LIST.CREATE_FOLDER')}>
                            <IconButton color="primary" sx={{ marginRight: '8px', width: 'auto', padding: '0' }} onClick={handleCreateFolder}>
                              <CreateNewFolderOutlinedIcon sx={{ width: '32px', height: '32px' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box>
                          <Link to={`/trainer/addWorkInstruction?parentFolderId=${parentFolderId}`}>
                            <Button className="text-ellipsis walkthrough-add-wi" onClick={() => trackEvent(AmplitudeEvents.CREATE_WORK_INSTRUCTION_STEP_1, { initiated_by: localStorage.getItem('email') })} variant="contained">
                              {t('WI_LIST.ADD_WORK_INSTRUCTION')}
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                {selectedFolderWiCard?.size > 0 && (
                  <Box component="div" className="right">
                    <Card sx={{ display: { xs: 'none', md: 'flex' }, borderRadius: '12px', height: '44px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px' }}>
                        <Typography sx={{ fontSize: '16px', marginRight: '16px' }}>{`${t('COMMON.SELECTED')} (${selectedFolderWiCard?.size})`}</Typography>
                        {selectedFolderWiCard?.size === 1 && JSON.parse(selectedFolderWiCard?.values()?.next()?.value).type === 'Folder' && (
                          <Typography>
                            <Button onClick={handleRename} sx={{ padding: 'auto', fontSize: '16px', marginLeft: '32px', color: (theme) => theme.palette.primary.main }} className="multiselect-option">
                              <RenameIcon />
                              &nbsp;{t('COMMON.RENAME')}
                            </Button>
                          </Typography>
                        )}
                        <Typography>
                          <Button onClick={handleMove} sx={{ padding: 'auto', fontSize: '16px', color: 'primary.main' }} className="multiselect-option">
                            <MoveIcon />
                            &nbsp;{t('COMMON.MOVE')}
                          </Button>
                        </Typography>
                        {!hideDuplicateButton && (
                          <Typography>
                            <Button onClick={handleDuplicateDialog} sx={{ padding: 'auto', fontSize: '16px', color: 'primary.main' }} className="multiselect-option">
                              <DuplicateIcon />
                              &nbsp;{t('COMMON.DUPLICATE')}
                            </Button>
                          </Typography>
                        )}
                        <Typography>
                          <Button onClick={handleDeleteDialog} sx={{ padding: 'auto', fontSize: '16px', marginRight: '32px', color: 'primary.main' }} className="multiselect-option">
                            <DeleteOutlinedIcon sx={{ width: '20', height: '20', color: 'black' }} />
                            &nbsp;{t('COMMON.DELETE')}
                          </Button>
                        </Typography>
                      </Box>

                      <Box>
                        <Button onClick={clearCardsSelection} sx={{ padding: 'auto', fontSize: '16px', pr: '16px', marginTop: '4px', marginRight: '4px' }}>
                          <CloseCardIcon />
                        </Button>
                      </Box>
                    </Card>
                  </Box>
                )}
              </>
            ) : null}
          </Paper>

          <Grid container spacing={1} sx={{ py: 2, px: 3, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={9}>
              <Box component="div" display="flex" justifyContent="flex-start">
                <Typography variant="h6" component="div" sx={{ mr: 1, fontSize: '13px' }}>
                  {' '}
                  {t('WI_LIST.ALL_WORK_INSTRUCTIONS') as string}{' '}
                </Typography>
                {workInstructions && (
                  <Avatar sx={{ bgcolor: 'grey.300', color: '#000', height: '24px', padding: '0', fontSize: '13px' }} variant="rounded">
                    {' '}
                    {totalWorkInstructionsLength}{' '}
                  </Avatar>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box component="div" display="flex" justifyContent="flex-end">
                <Box component="div" mr={1} onClick={(e) => setOpen(true)}>
                  <SortDesc />
                </Box>
                <Box
                  component="div"
                  mr={1}
                  onClick={(e) => {
                    setFilterOpen(true);
                  }}
                >
                  <ActionFilter />
                </Box>
                <Tooltip title="Refresh work instructions">
                  <IconButton color="primary" sx={{ mr: props.isTrainer ? 1 : 0, borderRadius: '8px', p: 0 }} onClick={handleReloadData}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" sx={{ display: 'flex', alignItems: 'flex-end' }} className="search-btn">
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => search(e?.target?.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <Search sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('COMMON.SEARCH') as string}
                    style={{ borderRadius: '8px !important' }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <AppliedFilterList appliedFilterList={currentView === SUPPORTED_VIEWS.WORK_INSTRUCTION ? appliedFilterList : procedureLibraryAppliedFilterList} onRemoveFilterClick={handleRemoveFilterClick} isTrainer={!!props.isTrainer} />
          {/* -------------- toolbar end --------- */}

          {currentView === SUPPORTED_VIEWS.WORK_INSTRUCTION ? (
            <InfiniteScroll dataLength={workInstructions.length || 10} next={() => loadData(parentFolderId ? parentFolderId : '', searchVal, sortVal, filterVal, currPage)} hasMore={hasNext} loader={<p>{totalFolderWorkInstructionLength < 1 ? t('COMMON.NO_FOLDERS_OR_WORK_INSTRUCTIONS_TO_DISPLAY') : hasNext ? t('COMMON.LOADING') : null}</p>}>
              {overview === 'table_view' ? (
                <Paper elevation={0} className="instructions-title-container" sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Box
                    component="div"
                    sx={{
                      width: '100%',
                      '> table': {
                        ' th:nth-child(1)': { width: '25%' },
                        ' th:nth-child(2)': { width: '40%' },
                        ' th:nth-child(3)': { width: '20%' },
                        ' th:nth-child(4)': { width: '15%' },
                      },
                    }}
                  >
                    <TableContainer
                      columns={workInstructionColumn}
                      data={workInstructionTableData}
                      onRowClick={folderWorkInstructionTableRowClick}
                      rowProps={(row) => ({
                        selected: row.original.type === 'Folder' ? selectedFolderWiCard?.has(JSON.stringify({ id: row.original.id, type: 'Folder', parentFolderId: row.original.parentFolderId || ''  })) : selectedFolderWiCard?.has(JSON.stringify({ id: row.original.id, type: 'WorkInstruction', parentFolderId: row.original.parentFolderId || ''  })),
                      })}
                      isWorkInstructionFolderTable={true}
                    />
                  </Box>
                </Paper>
              ) : (
                <Grid className="card-container" container spacing={{ xs: 3, md: 4 }} direction={'row'} alignItems={'stretch'} sx={{ marginBottom: { xs: '80px', md: '0px' } }}>
                  {createWiTour && (
                    <Grid item xs={12} md={overview === 'extended_view' ? 2.4 : 4} lg={overview === 'extended_view' ? 2.4 : 4}>
                      <TourCard overview={overview} />
                    </Grid>
                  )}
                  {isCreateFolder && (
                    <Grid item xs={12} md={overview === 'extended_view' ? 2.4 : 4} lg={overview === 'extended_view' ? 2.4 : 4} key="createFolderDialog">
                      <FolderCards companyId={companyId} isCreateFolder={isCreateFolder} folders={folders} onClick={() => {}} setFolders={setFolders} setIsCreateFolder={setIsCreateFolder} isTrainer={props.isTrainer} overview={overview} parentFolderId={parentFolderId} setParentFolderId={setParentFolderId} handleReloadData={handleReloadData} newFolderTitle={newFolderName} />
                    </Grid>
                  )}
                  {folders?.map((folder, index) => {
                    const isRename: boolean = isRenameNavBar && isRenameFolderId === folder.folderId;
                    return (
                      <Grid item xs={12} md={overview === 'extended_view' ? 2.4 : 4} lg={overview === 'extended_view' ? 2.4 : 4} key={folder.folderId}>
                        <FolderCards companyId={companyId} folder={folder} folders={folders} setFolders={setFolders} isTrainer={props.isTrainer} index={folder.folderId} onClick={() => handleClickFolderWi(folder.folderId, false)} onFolderIconClick={() => handleClickFolderWi(folder.folderId, false, true)} selected={selectedFolderWiCard.has(JSON.stringify({ id: folder.folderId, type: 'Folder', parentFolderId: folder.parentFolderId || '' }))} selectedFolderWiCard={selectedFolderWiCard} isRenameNavBar={isRename} setIsRenameNavBar={setIsRenameNavBar} overview={overview} parentFolderId={parentFolderId} setParentFolderId={setParentFolderId} handleReloadData={handleReloadData} checkedMoveToFolderId={checkedMoveToFolderId} setCheckedMoveToFolderId={setCheckedMoveToFolderId} clearCardsSelection={clearCardsSelection} handleDuplicateFolder={handleDuplicateFolder} setBreadCrumbRoute={setBreadCrumbRoute} />
                      </Grid>
                    );
                  })}
                  {workInstructions?.map((ws, index) => {
                    return (
                      <Grid item xs={12} md={overview === 'extended_view' ? 2.4 : 4} lg={overview === 'extended_view' ? 2.4 : 4} key={index}>
                        <WorkInstructionCards selectedFolderWiCard={selectedFolderWiCard} folders={folders} overview={overview} parentFolderId={parentFolderId} setParentFolderId={setParentFolderId} handleReloadData={handleReloadData} checkedMoveToFolderId={checkedMoveToFolderId} setCheckedMoveToFolderId={setCheckedMoveToFolderId} clearCardsSelection={clearCardsSelection} handleDuplicateFolder={handleDuplicateFolder} companyId={companyId} workInstruction={ws} isTrainer={props.isTrainer} workInstructionImageURL={imageObject ? imageObject[ws.workInstructionId] : ''} onClick={() => handleClickFolderWi(ws.workInstructionId, true, false, ws.is2d)} selected={selectedFolderWiCard?.has(JSON.stringify({ id: ws.workInstructionId, type: 'WorkInstruction', parentFolderId: ws.parentFolderId || '' }))} setIsCreateFolder={setIsCreateFolder} setBreadCrumbRoute={setBreadCrumbRoute} />
                      </Grid>
                    );
                  })}

                  {props.isTrainer && !workInstructions.length && !folders.length && <FolderEmptyState filterVal={filterVal} />}
                </Grid>
              )}
            </InfiniteScroll>
          ) : (
            <ProcedureLibrary />
          )}
          {/* <Box className="footer" component="div" sx={{ flexDirection: 'row', height: '80px', justifyContent: 'spaceBetween', display: { xs: 'flex', md: 'none' } }}>
            <StyledNavLink to="#" >
              Work Instructions
            </StyledNavLink>
            <StyledNavLink to="/home/workOrders/:companyID" style={{ pointerEvents: 'none', color: theme.palette.primary.main }} >
              Work Orders
            </StyledNavLink>
          </Box> */}
          {/* <SortDialog open={open} sort={sort} setSort={setSort} sortFilter={sortFilter} sortOptions={sortOptions} onClose={onSortClose}></SortDialog> */}
          {/* <FilterDialog activeFilters={filterVal} open={filterOpen} filter={filter} setFilter={setFilter} filterOptions={props.isTrainer ? filterOptionsTrainer : filterOptionsTrainee} onClose={onFilterClose} applyFilters={applyFilters}></FilterDialog> */}
        </div>
      )}
    </>
  );
};

export default TraineeHome;

const FolderEmptyState = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      component={'div'}
      sx={{
        display: 'block',
        width: 'inherit',
      }}
    >
      <Box
        component={'div'}
        sx={{
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <EmptyFolderIcon />
        {/* <Typography variant="h4">{t('HOMEPAGE.FOLDER_EMPTY_STATE.PRIMARY_TEXT')}</Typography> */}
        <Typography
          variant="h5"
          sx={{
            color: '#1C1D1F99',
          }}
        >
          {props.filterVal === '' ? t('HOMEPAGE.FOLDER_EMPTY_STATE.SECONDARY_TEXT') : t('HOMEPAGE.FOLDER_EMPTY_STATE.SECONDARY_FILTER_TEXT')}
        </Typography>
      </Box>
    </Box>
  );
};
