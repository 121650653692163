import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import HeightIcon from '@mui/icons-material/Height';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, MenuItem, Select, useTheme } from '@mui/material';
import { ThemeContext } from '@emotion/react';
import { lighten } from '../../../Cards/TourCard/TourCard';
import FileManagerService from '../../../../services/FileManager/FileManagerService';

export const columnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <Select sx={{ ml: '4px', boxShadow: 'none', outline: 'none', border: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.MuiOutlinedInput:focus': { border: 0 }, fontSize: '12px' }} onChange={(e) => setFilter(e.target.value)}>
      <MenuItem sx={{ fontSize: '12px' }} value={''}>
        All
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'Completed'}>
        Completed
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'In-Progress'}>
        In-Progress
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'Not Started'}>
        Not Started
      </MenuItem>
    </Select>
  );
};

export const SignOffColumnFilter = ({ column }) => {
  const { setFilter } = column;
  return (
    <Select sx={{ ml: '4px', boxShadow: 'none', outline: 'none', border: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.MuiOutlinedInput:focus': { border: 0 }, fontSize: '12px' }} onChange={(e) => setFilter(e.target.value)}>
      <MenuItem sx={{ fontSize: '12px' }} value={''}>
        All
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'Approved'}>
        Approved
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'Pending'}>
        Pending
      </MenuItem>
      <MenuItem sx={{ fontSize: '12px' }} value={'Rejected'}>
        Rejected
      </MenuItem>
    </Select>
  );
};
interface rowProps {
  selected?: boolean;
  disabled?: boolean
}
interface Props {
  columns: any;
  data: any;
  onRowClick?: (row) => void;
  commentThreadTable?: boolean;
  unreadCount?: any;
  checkedFolder?: any;
  isWorkInstructionFolderTable?: any;
  thumbnail?: any;
  setThumbnail?: any;
  rowProps?: (row: any) => rowProps;
  isMoveFolderTable?: boolean;
}
const TableContainer = ({ columns, data, onRowClick, commentThreadTable, unreadCount, checkedFolder, thumbnail, setThumbnail, isWorkInstructionFolderTable, rowProps,isMoveFolderTable }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const [rowId, setrowId] = useState();
  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setFilter } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
  );
  const handleMouseEnter = (e, id) => {
    setIsHover(true);
    setrowId(id);
  };

  const handleMouseLeave = (e, id) => {
    setIsHover(false);
    setrowId(undefined);
  };

  const theme = useTheme();
  return (
    <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              // @ts-ignore
              <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ borderBottom: 'none', background: lighten(theme.palette.primary.main, 0.96), fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', height: '47px', paddingLeft: index === 0 ? '8px' : '0', fontSize: commentThreadTable ? '14px' : null, cursor: 'pointer' }}>
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                  {column.render('Header')}
                  {/* @ts-ignore */}
                  {column.Filter ? column.render('Filter') : null}
                  {/* @ts-ignore */}
                  {column.isSorted ? column.isSortedDesc ? <ArrowDownwardIcon fontSize="small" sx={{ ml: '9px' }} /> : <ArrowUpwardIcon sx={{ ml: '9px' }} fontSize="small" /> : column.disableSortBy ? '' : <UnfoldMoreIcon sx={{ ml: '9px' }} fontSize="small" />}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const additionalRowProps: any = rowProps ? rowProps(row) : { selected: false, disabled: false };
          const isSelected = additionalRowProps.selected || (checkedFolder && row.id === checkedFolder) || (onRowClick && isHover && rowId === row.id) || (unreadCount && unreadCount[row?.id]?.unreadCount > 0 && commentThreadTable);

          let rowStyle:  React.CSSProperties = { 
              height: '57px', 
              fontSize: '12px', 
              borderBottom: '1px solid black', 
              backgroundColor: 'white', 
              cursor: 'default' 
          }

          if(onRowClick && isHover && rowId === row.id){
            rowStyle.cursor = 'pointer'
          }

          if(isSelected) {
            rowStyle.backgroundColor = theme.palette.primary.main + '1c'
          }
          if(additionalRowProps.disabled){
            rowStyle.cursor = 'not-allowed';
            rowStyle.opacity = 0.3;
            rowStyle.backgroundColor = 'white';
          }
          return (
            <tr
              onMouseEnter={(e) => {
                if (onRowClick) handleMouseEnter(e, row.id);
              }}
              onMouseLeave={(e) => {
                if (onRowClick) handleMouseLeave(e, row.id);
              }}
              {...row.getRowProps()}
              style={rowStyle}
              onClick={() => {
                if (!additionalRowProps.disabled && onRowClick) {
                  onRowClick(row);
                }
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
                    {cell.value === null ? '-' : cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableContainer;
