import { environment } from '../../environment/environment';
import { rbacAxiosInstance as http } from '../http-common';
import { DataResponse } from '../service-models';

const headers = {
  'Content-Type': 'application/json',
  appid: environment.AppConfigs.appid,
};

const getUser = () => {
  const url = `/users/scopes/oid`;
  return http.get<DataResponse<any>>(url, { headers });
};

const getUserByUsername = (username: string) => {
  const url = `/users/get/username/${username}`;
  return http.get<DataResponse<any>>(url, { headers });
};

const RbacService = {
  getUser,
  getUserByUsername,
};

export default RbacService;
