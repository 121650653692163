import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMsal } from '@azure/msal-react';
import { AccountCircle } from '@mui/icons-material';
import './versionDialog.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import ApprovalDialog from '../ApprovalDialog/ApprovalDialog';
import PublishDialog from '../PublishDialog/PublishDialog';
import { FormControl, FormLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { Toast } from '../../../Notifications/Notification';
import { getMessage } from '../../../../shared/utils/message-helpers';
import { useTranslation } from 'react-i18next';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../../shared/enums';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '12px 24px !important',
  },
  '& .MuiDialogActions-root': {
    padding: '0px 24px 24px',
  },
}));

interface Props {
  openDialog: boolean;
  closeDialog: () => void;
  procedure: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="title-margin" sx={{ width: '387px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function VersionDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [newVersion, setNewVersion] = React.useState({
    minorVersion: '',
    majorVersion: '',
  });
  const [versionLoading, setVersionLoading] = React.useState(false);

  const [showPublishDialog, setShowPublishDialog] = React.useState<boolean>(false);
  const { accounts } = useMsal();
  const [releaseNotes, setReleaseNotes] = React.useState('');
  const [integrationSystem, setIntegrationSystem] = React.useState();
  const [integrationInstances, setIntegrationInstances] = React.useState<any>([]);
  const theme = useTheme();
  const name = accounts[0] && accounts[0].name;
  const oid = (accounts[0] && accounts[0].idTokenClaims && accounts[0].idTokenClaims.oid) || '';

  const tabs = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const closeDialogHandler = () => {
    setShowPublishDialog(false);
  };

  const getNewVersion = (id) => {
    setVersionLoading(true);
    TrainerService.getNewVersion(props.procedure?.procedureId)
      .then((res) => {
        setVersionLoading(false);
        setNewVersion(res.data.data);
      })
      .catch(({ response }) => {
        setVersionLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  useEffect(() => {
    setOpen(props.openDialog);
    if (props.openDialog && props.procedure?.versionId.length > 0) getNewVersion(props.procedure?.procedureId);
  }, [props.openDialog]);

  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  const handleApprove = (e) => {
    e.preventDefault();
    setShowPublishDialog(true);
    handleClose();
  };

  const handlereleaseNotes = () => (event) => {
    setReleaseNotes(event.target.value);
  };

  const publishVersion = async () => {
    let integration = {};
    if (integrationSystem) {
      integration = { integrationId: integrationSystem };
    }
    const res = await TrainerService.publishProcedure(props.procedure?.procedureId, integration);
    return res;
  };

  const updateStyles = {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    minHeight: '32px !important',
    maxHeight: '32px !important',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      opacity: '1',
    },
  };

  useEffect(() => {
    TrainerService.getIntegrations().then((res) => {
      setIntegrationInstances(res.data.data.systems);
    });
  }, []);

  const handleIntegrationChange = (e) => {
    if (e.target.value === 'notRequired') {
      setIntegrationSystem(undefined);
    } else {
      setIntegrationSystem(e.target.value);
    }
  };

  const { t } = useTranslation();
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="version-dialog">
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.procedure?.versionId.length > 0 ? t('PROCEDURE.VERSION_DETAILS') : t('PROCEDURE.VERSION_INFO')}
        </BootstrapDialogTitle>
        <form onSubmit={handleApprove}>
          <DialogContent dividers style={{ border: 'none' }}>
            <Box component="div" sx={{ borderColor: 'divider' }}>
              <div className="dialog-header">{t('PROCEDURE.VERSION_TYPE')}</div>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                <Tab
                  sx={{
                    ...updateStyles,
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  label={t('PROCEDURE.MINOR_UPDATE')}
                />
                <Tab
                  sx={{
                    ...updateStyles,
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  label={t('PROCEDURE.MAJOR_UPDATE')}
                />
              </Tabs>
              <div className="version-content">
                {props.procedure?.versionId.length > 0 && (
                  <div style={{ marginRight: '10px' }}>
                    <div className="dialog-header">{t('PROCEDURE.CURRENT')}</div>

                    <div className="version">
                      {t('PROCEDURE.CARD_VERSION')} {props.procedure?.version}
                    </div>
                  </div>
                )}
                <div>
                  <div className="dialog-header">{t('PROCEDURE.NEW')}</div>
                  <div className="version">
                    {t('PROCEDURE.CARD_VERSION')} {props.procedure?.versionId.length > 0 ? (value === 0 ? newVersion.minorVersion : newVersion.majorVersion) : value === 0 ? '0.1' : '1.0'}
                  </div>
                </div>
              </div>

              <FormControl required sx={{ width: '100%' }}>
                <FormLabel className="dialog-header" required>
                  {t('PROCEDURE.RELEASE_NOTES')}
                </FormLabel>
                <TextField
                  id="outlined-basic"
                  multiline
                  required
                  FormHelperTextProps={{
                    style: {
                      display: 'flex',
                      justifyContent: 'end',
                    },
                  }}
                  helperText={`${500 - releaseNotes.length} ${t('COMMON.CHARACTERS_LEFT')}`}
                  onChange={handlereleaseNotes()}
                  sx={{
                    width: '100% !important',
                    margin: '0px',
                  }}
                  inputProps={{
                    maxLength: 500,
                    style: {
                      height: '90px',
                    },
                  }}
                  variant="outlined"
                />
              </FormControl>

              {props.procedure?.visibilityScope !== PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? (
                <Box component="div" sx={{ margin: '8px 0px' }}>
                  <FormControl fullWidth>
                    <FormLabel className="dialog-header">{t('BUTTON.PUBLISH_TO_LEARNING_MANAGEMENT_SYSTEM')}</FormLabel>

                    <Select value={integrationSystem ? integrationSystem : 'notRequired'} onChange={handleIntegrationChange} inputProps={{ 'aria-label': 'Preffered language' }} sx={{ height: '2.5rem' }} fullWidth>
                      <MenuItem value="notRequired" key="notRequired">
                        {t('BUTTON.NOT_REQUIRED')}
                      </MenuItem>
                      {integrationInstances?.map((integration) => (
                        <MenuItem key={integration.integrationId} value={integration.integrationId}>
                          {integration.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" sx={{ borderRadius: '8px' }} type="submit" disabled={versionLoading}>
              {' '}
              {t('PROCEDURE.PROCEED_TO_APPROVAL')}{' '}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      {showPublishDialog && <PublishDialog openDialog={showPublishDialog} closeDialog={closeDialogHandler} integrationSystem={integrationSystem} procedure={props.procedure} publishVersion={publishVersion} releaseNotes={releaseNotes} updateType={value === 0 ? 'minor' : 'major'} version={props.procedure?.versionId.length > 0 ? (value === 0 ? newVersion.minorVersion : newVersion.majorVersion) : value === 0 ? '0.1' : '1.0'} />}
    </div>
  );
}
