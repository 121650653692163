import { useContext, useEffect, useState } from 'react';
import { mediaType } from '../../../../entities/Trainee/WorkInstructions';
import MediaUploadDialog from '../../../Trainer/Dialog/MediaUploadDialog/MediaUploadDialog';
import { DialogTitle, Drawer, FormControl, FormControlLabel, IconButton, InputAdornment, List, OutlinedInput, Typography, FormHelperText, Box, Button, DialogActions, CircularProgress, Divider, useTheme } from '@mui/material';
import { CloseIcon } from '../../../Icons/CdsIcons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useTranslation } from 'react-i18next';
import MediaRef from '../../../Trainer/Dialog/MediaUploadDialog/MediaRef';
import Loading from '../../../Loading/Loading';
import { useFormik } from 'formik';
import { environment } from '../../../../environment/environment';
import { IProcedures, IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import { Toast } from '../../../Notifications/Notification';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import TutorialService from '../../../../services/trainee/TraineeService';
import moment from 'moment';
import { Buffer } from 'buffer';
import UserContext from '../../../../context/UserContext';
import { getFormattedDateFromUnixTime } from '../../../../shared/utils/formateDate';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { convertFileBlobToMediaType } from '../../../../services/Mapper/Mapper';
import FileHelper from '../../../../services/FileManager/FileHelper';

interface Props {
  index?: number;
  thread?: any;
  traineesData?: any;
  showRightPaneComment?: boolean;
  setShowRightPaneComment?: any;
  activeThreadId?: string;
  setActiveThreadId?: any;
  procedure?: IProcedures | null;
  workInstruction?: IWorkInstructions | null;
  isTrainer?: boolean;
  stepIndex?: number;
  notification?: any;
  stepCommentThreads?: any;
}

const ThreadComment = (props: Props) => {
  const [commentCountOnThread, setCommentCountOnThread] = useState<number>(0);
  const [showMoreCommentOnThread, setShowMoreCommentOnThread] = useState<boolean>(false);
  const showCommentOnThreadHandler = () => {
    setShowMoreCommentOnThread(!showMoreCommentOnThread);
  };
  const listStepCommentThread = (threadId: string) => {
    TutorialService.listStepComments(threadId)
      .then((e) => {
        setCommentCountOnThread(e?.data?.data?.comments?.slice(0, -1).length);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      });
  };
  useEffect(() => {
    listStepCommentThread(props?.thread?._id);
  }, [showMoreCommentOnThread]);

  const { t } = useTranslation();
  return (
    <>
      <List key={props.index} sx={{ marginTop: '24px' }}>
        <Typography sx={{ display: 'flex', flexDirection: 'row', justify: 'space-between' }}>
          <AccountCircleIcon sx={{ width: '24px', height: '24px', borderRadius: '18px' }} />
          <Typography sx={{ marginLeft: '0.5em', size: '14px', color: '#12181F' }}>{`${props.thread?.lastComment?.userFirstName} ${props.thread?.lastComment?.userLastName}`} </Typography>
          <Typography sx={{ marginLeft: '1em', weight: '400', size: '12px', color: '#12181F66' }}>{`${getFormattedDateFromUnixTime(props.thread?.firstComment?.createdOn / 1000)}`}</Typography>
        </Typography>
        <Typography sx={{ ml: '4px', marginTop: '8px', width: '376px', weight: '400', size: '14px', color: '#12181F99' }}>{props.thread?.firstComment?.comment}</Typography>
      </List>
      <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        {props.thread?.firstComment?.files?.map((file, index) => (
          <CommentFilePreview file={file} />
        ))}
      </Box>
      <List key={props.index}>
        {showMoreCommentOnThread && <AddCommentToExistingThread threadId={props.thread._id} procedure={props.procedure} showMoreCommentOnThread={showMoreCommentOnThread} />}
        <Typography sx={{ float: 'left', textAlign: 'left', mt: '8px', opacity: '0.8' }}>
          <Button sx={{ color: '#12181F' }} onClick={showCommentOnThreadHandler}>
            {showMoreCommentOnThread ? t('COMMON.SHOW_LESS') : `${commentCountOnThread > 0 ? commentCountOnThread : ''} ${commentCountOnThread > 1 ? t('COMMON.REPLIES') : t('COMMON.REPLY')}`}
          </Button>
        </Typography>
      </List>
    </>
  );
};

const AddCommentToExistingThread = (props) => {
  const [showMediaUploadDialog, setShowMediaUploadDialog] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<mediaType>>([]);
  const [mentorIds, setMentorIds] = useState<Array<any>>([]);
  const [mediaFiles, setMediaFiles] = useState<Array<mediaType>>([]);
  const [stepComments, setstepComments] = useState<any>([]);
  const [submitDisableButton, setSubmitDisableButton] = useState<boolean>(false);

  const handleMediaUploadDialog = () => {
    setShowMediaUploadDialog(true);
  };
  const closeMediaDialogHandler = () => {
    setShowMediaUploadDialog(false);
  };

  const handleSelectedFiles = (files) => {
    setSelectedFiles(files);
  };

  const { currentUser } = useContext(UserContext);
  const submitComment = () => {
    if (formik.values.commentText) {
      setMentorIds([]);
      setSubmitDisableButton(true);
      saveCurrentMediaRef(selectedFiles[0], true);
      formik.setFieldValue('commentMedia', []);
      setSelectedFiles([]);
    }
  };

  const { t } = useTranslation();

  const listStepCommentThread = (threadId: string) => {
    TutorialService.listStepComments(threadId)
      .then((e) => {
        setstepComments(e?.data?.data?.comments?.slice(0, -1));
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      });
  };

  useEffect(() => {
    if (props.showMoreCommentOnThread) {
      listStepCommentThread(props.threadId);
    }
  }, [props.showMoreCommentOnThread]);
  useEffect(() => {
    if (mediaFiles.length > 0) {
      saveCurrentMediaRef(mediaFiles[0], false);
    } else {
      if (formik.values.commentText) {
        let data = {
          comment: formik.values.commentText,
          files: mentorIds,
        };
        if (props?.threadId) {
          TutorialService.addCommentToThread(props.threadId, data)
            .then(() => {
              Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.COMMENT_ADDED_SUCCESSFULLY'));
              listStepCommentThread(props.threadId);
              setSubmitDisableButton(false);
              formik.setFieldValue('commentText', '');
            })
            .catch(({ response }) => {
              setSubmitDisableButton(false);
              Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
        }
      }
    }
  }, [mediaFiles]);

  async function saveCurrentMediaRef(file, initial) {
    if (file) {
      let nameArray = file.title.split('.');
      let extension = nameArray[nameArray.length - 1];
      let extensionKey = '';

      if (extension === 'jpg' || extension === 'jpeg') {
        extensionKey = 'image/jpeg';
      } else if (extension === 'png') {
        extensionKey = 'image/png';
      } else if (extension === 'mp4') {
        extensionKey = 'video/mp4';
      } else if (extension === 'mov') {
        extensionKey = 'video/quicktime';
      } else if (extension === 'pdf') {
        extensionKey = 'application/pdf';
      }

      const metaData = { organizationId: currentUser?.organizationId, workInstructionId: props?.procedure?.workInstructionId };
      await FileManagerService.uploadImage(file.dataUrl, file.title, file.fileType, extensionKey, metaData).then((response: any) => {
        const mentorID = response.data.id;

        setMentorIds((mentorids) => [...mentorids, { fileId: mentorID, fileName: file.title, mimeType: extensionKey }]);
      });

      if (file.url) {
        let shortUrl = file.url.substring(0, 15);
        shortUrl = shortUrl.concat('  .....');
        Toast.success(t('COMMON.SAVING_LINK'), `${file?.title}`);
      } else {
        Toast.success(t('COMMON.UPLOADING_FILE'), `${file?.title}`);
      }

      setSelectedFiles([]);
    }
    if (initial) {
      setMediaFiles(selectedFiles.slice(1));
    } else {
      setMediaFiles(mediaFiles.slice(1));
    }
  }

  const formik = useFormik({
    initialValues: {
      commentText: '',
      commentMedia: [],
    },

    onSubmit: () => {},
  });
  return (
    <>
      {showMediaUploadDialog && <MediaUploadDialog closeDialog={closeMediaDialogHandler} openDialog={showMediaUploadDialog} editMode={false} style={{ padding: '24px' }} selectedFiles={selectedFiles} setSelectedFiles={handleSelectedFiles} />}
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ textAlign: 'center' }}>
          <FormControlLabel
            control={<></>}
            label={
              <Typography paddingX={2} paddingY={1} sx={{ float: 'left', mt: '24px', fontSize: '12px', opacity: '0.8' }}>
                {t('COMMON.REPLY')}
              </Typography>
            }
          />
          <OutlinedInput
            id="outlined-basic"
            multiline
            minRows={4}
            required
            placeholder={`${t('COMMON.WRITE_REPLY')}...`}
            name="commentText"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={formik.values.commentText}
            sx={{ width: '368px', borderRadius: '8px' }}
            endAdornment={
              <InputAdornment position="end" sx={{ marginTop: 'auto', marginBottom: '12px' }} onClick={handleMediaUploadDialog}>
                <IconButton aria-label="toggle password visibility" edge="end">
                  <UploadFileIcon />
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              maxLength: 500,
            }}
          />

          <FormHelperText className="helperText" sx={{ weight: '400', marginTop: '8px', size: '12px' }}>
            {500 - formik.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
          </FormHelperText>
        </FormControl>
        <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          {selectedFiles?.map((file, index) => {
            return <MediaRef key={index} aReference={file} index={index} commentMediaRef={true} />;
          })}
        </Box>
        <DialogActions style={{ display: 'flex', justifyContent: 'end', marginTop: '24px' }}>
          <Button
            variant="contained"
            disabled={submitDisableButton}
            onClick={() => {
              submitComment();
            }}
          >
            {submitDisableButton && <CircularProgress sx={{ marginRight: '8px' }} size="12px" />}
            {t('BUTTON.SUBMIT')}
          </Button>
        </DialogActions>
        {stepComments?.map((comment, index) => {
          return (
            <>
              <List key={index} sx={{ marginTop: '24px' }}>
                <Typography sx={{ display: 'flex', flexDirection: 'row', justify: 'space-between' }}>
                  <AccountCircleIcon sx={{ width: '24px', height: '24px', borderRadius: '18px' }} />
                  <Typography sx={{ marginLeft: '0.5em', size: '14px', color: '#12181F' }}>{`${comment?.userFirstName} ${comment?.userLastName}`} </Typography>
                  <Typography sx={{ marginLeft: '1em', weight: '400', size: '12px', color: '#12181F66' }}>{`${getFormattedDateFromUnixTime(comment?.createdOn / 1000)}`}</Typography>
                </Typography>
                <Typography sx={{ ml: '4px', marginTop: '8px', width: '376px', weight: '400', size: '14px', color: '#12181F99' }}>{comment.comment}</Typography>
              </List>
              <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                {comment?.files?.map((file, index) => (
                  <CommentFilePreview file={file} />
                ))}
              </Box>
            </>
          );
        })}
      </form>
    </>
  );
};

const CommentFilePreview = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { file } = props;
  const [fileObject, setFileObject] = useState<any>(null);

  useEffect(() => {
    if (file && file.fileId) {
      let aReference: any = {};

      if (file.isBlobUpload) {
        const fileType = file.mimeType;
        if (fileType == 'application/pdf') {
          FileHelper.getObjectUrlByFileId(file.fileId, fileType).then((objectUrl) => {
            aReference.dataUrl = objectUrl;
          });
        } else {
          aReference.dataUrl = `${environment.AppConfigs.fileManagerApi}/v1/files/${file.fileId}?cache=true`;
        }

        aReference.title = file.fileName;
        aReference.fileType = file.mimeType;
      } else {
        FileManagerService.getFileById(file.fileId).then((response) => {
          const base64Url = Buffer.from(response.data).toString();
          aReference.dataUrl = base64Url.replaceAll('"', '');
          aReference.title = file.fileName;
          aReference.fileType = file.mimeType;
        });
      }
      setFileObject(aReference);
      setIsLoading(false);
    }
  }, []);

  return isLoading ? (
    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 100px', borderRadius: '10px', border: '4px solid #bfbfbf', height: '100px', width: '100px', margin: '1.5%' }}>
      <Box component={'div'} sx={{ width: '100%', height: 'calc( 100% - 16px )', display: 'flex', position: 'relative' }}>
        <Box component={'div'} sx={{ width: '100%', height: '100%', backgroundColor: '#a1a1a17d', display: 'flex', position: 'absolute', left: '0px', top: '0px', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  ) : (
    <MediaRef key={file?.fileId} aReference={fileObject} index={file?.fileId} commentMediaRef={true} />
  );
};

export const CommentSideBar = (props) => {
  const [activeRow, setActiveRow] = useState(-1);
  const [showMediaUploadDialog, setShowMediaUploadDialog] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [loading, setLoading] = useState(false);
  const [isThreadExist, setIsThreadExist] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<Array<File>>([]);
  const studioUrl = environment.AppConfigs.studioURL;
  const [mentorIds, setMentorIds] = useState<Array<any>>([]);
  const [stepComments, setstepComments] = useState<any>([]);
  const [submitDisableButton, setSubmitDisableButton] = useState<boolean>(false);
  const [is2d, setIs2d] = useState<boolean>(false);

  const listStepCommentThread = (threadId: string) => {
    TutorialService.listStepComments(threadId)
      .then((e) => {
        setstepComments(e?.data?.data?.comments);
        setIs2d(e?.data?.data?.is2d);
        setIsThreadExist(true);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
      });
  };

  useEffect(() => {
    if (props.activeThreadId && props.showRightPaneComment === true) listStepCommentThread(props.activeThreadId);
  }, [props.activeThreadId, props.showRightPaneComment]);

  const handleMediaUploadDialog = () => {
    setShowMediaUploadDialog(true);
  };
  const closeMediaDialogHandler = () => {
    setShowMediaUploadDialog(false);
  };

  const handleSelectedFiles = (files) => {
    setSelectedFiles(files);
  };
  const { t } = useTranslation();
  const toggleDrawerComment = (status) => {
    props.setShowRightPaneComment(status);

    if (status === false) {
      setActiveRow(-1);
    }
  };

  const { currentUser } = useContext(UserContext);
  const submitComment = () => {
    if (formik.values.commentText) {
      setSubmitDisableButton(true);
      setMentorIds([]);
      saveCurrentMediaRef(selectedFiles[0], true);
      formik.setFieldValue('commentMedia', []);
      setSelectedFiles([]);
    }
  };
  useEffect(() => {
    if (mediaFiles.length > 0) {
      saveCurrentMediaRef(mediaFiles[0], false);
    } else {
      if (formik.values.commentText) {
        let data = {
          comment: formik.values.commentText,
          files: mentorIds,
        };
        formik.setFieldValue('commentText', '');
        if (props?.activeThreadId) {
          TutorialService.addCommentToThread(props.activeThreadId, data)
            .then(() => {
              Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.COMMENT_ADDED_SUCCESSFULLY'));
              listStepCommentThread(props.activeThreadId);
              setSubmitDisableButton(false);
            })
            .catch(({ response }) => {
              setSubmitDisableButton(false);
              Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
        } else {
          TutorialService.addCommentToStep(props.procedure.procedureId, props.stepIndex, data)
            .then(() => {
              if (props.activeThreadId) {
                listStepCommentThread(props.activeThreadId);
              } else {
                props.setThreadUpdate(true);
              }
              setIsThreadExist(true);
              setSubmitDisableButton(false);
              Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.THREAD_ADDED_SUCCESSFULLY'));
            })
            .catch(({ response }) => {
              setSubmitDisableButton(false);
              Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
        }
      }
    }
  }, [mediaFiles]);

  async function saveCurrentMediaRef(file, initial) {
    if (file) {
      const metaData = { organizationId: currentUser?.organizationId, workInstructionId: props?.notification?.target?.workInstructionId };
      await FileManagerService.fileUploadBlob(file, metaData).then((response: any) => {
        const mentorID = response.data.id; // file id

        setMentorIds((mentorids) => [...mentorids, { fileId: mentorID, fileName: file.name, mimeType: file.type, isBlobUpload: true }]);
      });
      setSelectedFiles([]);
      if (file.url) {
        let shortUrl = file.url.substring(0, 15);
        shortUrl = shortUrl.concat('  .....');
        Toast.success(t('COMMON.SAVING_LINK'), `${file?.name}`);
      } else {
        Toast.success(t('COMMON.UPLOADING_FILE'), `${file?.name}`);
      }
    }
    if (initial) {
      setMediaFiles(selectedFiles.slice(1));
    } else {
      setMediaFiles(mediaFiles.slice(1));
    }
  }

  const redirectToComment = () => {
    var query = new URLSearchParams();
    query.append('procedureId', props?.procedure?.procedureId || props?.notification?.target?.procedureId);
    query.append('model', props?.procedure?.workInstructionId || props?.notification?.target?.workInstructionId);
    query.append('org', props?.procedure?.organizationId || currentUser?.organizationId);
    if (is2d) {
      query.append('workInstructionType', '2d');
    }
    query.append('isQueryExist', 'true');
    // query.append('authToken', authToken);
    var url = `${studioUrl}${query.toString()}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const formik = useFormik({
    initialValues: {
      commentText: '',
      commentMedia: [],
    },

    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      commentText: '',
      commentMedia: [],
    });
  }, []);
  const theme = useTheme();

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = (R * (100 - percent) + percent * 255) / 100;
    G = (G * (100 - percent) + percent * 255) / 100;
    B = (B * (100 - percent) + percent * 255) / 100;

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }
  const themePrimaryExtraLight = shadeColor(theme.palette.primary.main, 87);

  return (
    <>
      {loading && <Loading />}
      {showMediaUploadDialog && <MediaUploadDialog closeDialog={closeMediaDialogHandler} openDialog={showMediaUploadDialog} editMode={false} style={{ padding: '24px' }} loading={loading} selectedFiles={selectedFiles} setSelectedFiles={handleSelectedFiles} />}

      <Drawer
        anchor={'right'}
        open={props.showRightPaneComment}
        onClose={() => toggleDrawerComment(false)}
        PaperProps={{
          sx: { width: '432px', padding: '24px' },
        }}
      >
        {props.threadLoading && (
          <Box sx={{ position: 'relative', height: '100%' }}>
            <Loading />
          </Box>
        )}
        {!props.threadLoading && (
          <>
            <>
              <DialogTitle sx={{ fontSize: '16px', padding: '2px 12px 2px 12px', marginBottom: '22px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', top: '14px', left: '14px' }}>
                  <Typography sx={{ width: '245px', fontWeight: '600', fontSize: '18px', lineHeight: '21.6px' }}>
                    {stepComments[stepComments?.length - 1]?.userFirstName ? `${stepComments[stepComments?.length - 1]?.userFirstName} ${stepComments[stepComments?.length - 1]?.userLastName}` : null} {t('NAVBAR.COMMENTS')}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => toggleDrawerComment(false)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      padding: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
              {isThreadExist && props.istrainer && (
                <List key="listTitle" sx={{ marginTop: '24px' }}>
                  <Typography sx={{ display: 'flex', flexDirection: 'row', justify: 'space-between' }}>
                    <AccountCircleIcon sx={{ width: '24px', height: '24px', borderRadius: '18px' }} />
                    <Typography sx={{ marginLeft: '0.5em', size: '14px', color: '#12181F' }}>{stepComments ? `${stepComments[0]?.userFirstName} ${stepComments[0]?.userLastName}` : null} </Typography>
                    <Typography sx={{ marginLeft: '1em', fontWeight: '400', size: '14px', color: '#12181F66' }}>{stepComments ? `${getFormattedDateFromUnixTime(stepComments[0]?.createdOn / 1000)}` : null}</Typography>
                  </Typography>
                  <Typography sx={{ ml: '4px', marginTop: '8px', width: '376px', weight: '400', size: '14px', color: '#12181F99' }}>{stepComments ? `${stepComments[0]?.comment}` : null}</Typography>
                </List>
              )}

              <form onSubmit={formik.handleSubmit}>
                <FormControl sx={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={<></>}
                    label={
                      <Typography paddingX={2} paddingY={1} sx={{ float: 'left', mt: '24px', fontSize: '12px', fontWeight: '500', opacity: '0.8' }}>
                        {t('COMMON.ADD_COMMENTS')}
                      </Typography>
                    }
                  />
                  <OutlinedInput
                    id="outlined-basic"
                    multiline
                    minRows={4}
                    required
                    name="commentText"
                    placeholder={`${t('COMMON.WRITE_COMMENT')}...`}
                    onChange={formik.handleChange}
                    value={formik.values.commentText}
                    sx={{ width: '368px', borderRadius: '8px' }}
                    endAdornment={
                      <InputAdornment position="end" sx={{ marginTop: 'auto', marginBottom: '12px' }} onClick={handleMediaUploadDialog}>
                        <IconButton aria-label="toggle password visibility" edge="end">
                          <UploadFileIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      maxLength: 500,
                    }}
                  />

                  <FormHelperText className="helperText" sx={{ weight: '400', marginTop: '8px', size: '12px' }}>
                    {500 - formik.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                  </FormHelperText>
                </FormControl>
                <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                  {selectedFiles?.map((file, index) => {
                    let fileToShow = convertFileBlobToMediaType(file);
                    return <MediaRef key={index} aReference={fileToShow} index={index} commentMediaRef={true} />;
                  })}
                </Box>
                <Button
                  variant="contained"
                  sx={{ m: '8px 0px 8px 0px' }}
                  disabled={submitDisableButton}
                  onClick={() => {
                    submitComment();
                  }}
                >
                  {submitDisableButton && <CircularProgress sx={{ marginRight: '8px' }} size="12px" />}
                  {t('BUTTON.SUBMIT')}
                </Button>
              </form>
              {stepComments?.map((comment, index) => {
                return (
                  <>
                    <List key={index} sx={{ marginTop: '24px' }}>
                      <Typography sx={{ display: 'flex', flexDirection: 'row' }}>
                        <AccountCircleIcon sx={{ width: '24px', height: '24px', borderRadius: '18px' }} />
                        <Typography sx={{ marginLeft: '0.5em', size: '14px', color: '#12181F' }}>{`${comment?.userFirstName} ${comment?.userLastName}`} </Typography>
                        <Typography sx={{ marginLeft: '1em', weight: '400', size: '12px', color: '#12181F66' }}>{`${getFormattedDateFromUnixTime(comment?.createdOn / 1000)}`}</Typography>
                      </Typography>
                      <Typography sx={{ ml: '4px', marginTop: '8px', width: '376px', weight: '400', size: '14px', color: '#12181F99' }}>{comment.comment}</Typography>
                    </List>
                    <Box component={'div'} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                      {comment?.files?.map((file, index) => (
                        <CommentFilePreview file={file} />
                      ))}
                    </Box>
                  </>
                );
              })}

              {props?.stepCommentThreads?.map((thread, index) => {
                return (
                  <>
                    <Divider />
                    <ThreadComment thread={thread} index={index} />
                  </>
                );
              })}

              <DialogActions style={{ display: 'flex', justifyContent: 'end', marginTop: '24px' }}>
                <Button
                  variant="outlined"
                  sx={{ m: '24px 0px 24px 0px' }}
                  onClick={() => {
                    toggleDrawerComment(false);
                  }}
                >
                  {t('3D_VIEWER.CLOSE')}
                </Button>
                {props.isTrainer && (
                  <Button
                    variant="contained"
                    sx={{ m: '24px 0px 24px 0px' }}
                    onClick={() => {
                      redirectToComment();
                    }}
                  >
                    {t('COMMON.GO_TO_ALL_COMMENTS')}
                  </Button>
                )}
              </DialogActions>
            </>
          </>
        )}
      </Drawer>
    </>
  );
};
