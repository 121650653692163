import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(languageDetector)
.use(HttpApi)
.init({
  //resources,
  lng: "en",
  fallbackLng: "en",
  ns:['translation','error','success', 'demoTour'],
  detection: {
    order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
    caches: ['cookie']
  },

  

  //keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false
  },
  backend : {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  }
});

export default i18n;
