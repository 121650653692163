import React from 'react';
import { createContext, Dispatch } from 'react';

export interface ITranslation {
  CORE_PROCEDURES: {
    EN: string;
    FR: string;
    DE: string;
    ESP: string;
  };
  PREREQUISITES: {
    EN: string;
    FR: string;
    DE: string;
    ESP: string;
  };
}

const translation: ITranslation = {
  CORE_PROCEDURES: {
    EN: '',
    FR: '',
    DE: '',
    ESP: '',
  },
  PREREQUISITES: {
    EN: '',
    FR: '',
    DE: '',
    ESP: '',
  },
};

const refreshProcedure: boolean = false;

const translationFn: Dispatch<React.SetStateAction<ITranslation>> = () => {};
const refreshProcedureFn: Dispatch<React.SetStateAction<boolean>> = () => {};

const TranslationContext = createContext({
  currentTranslation: translation,
  setCurrentTranslation: translationFn,
  refreshProcedure: refreshProcedure,
  setRefreshProcedure: refreshProcedureFn,
});

export default TranslationContext;
